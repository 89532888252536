import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const SaveModal = ({ closeModal, setIsSaving, handleSaveData, selectedDataType, setSelectedDataType, setIsOutWard }) => {
  const [inwardData, setInwardData] = useState([]);
  const [outwardData, setOutwardData] = useState([]);

  // Fetch inward and outward data
  useEffect(() => {
    axios.get(`${config.apiUrl}/saveio/getsaveinward`)
      .then((response) => setInwardData(response.data))
      .catch((err) => console.error(err));

    axios.get(`${config.apiUrl}/saveio/getsaveoutward`)
      .then((response) => setOutwardData(response.data))
      .catch((err) => console.error(err));
  }, []);

  const handleCheckboxClick = (id) => {
    axios.get(`${config.apiUrl}/saveio/${selectedDataType}/${id}`)
      .then((response) => {
        const fetchedData = response.data;

        if (selectedDataType === 'outward') {
          setIsOutWard(true);
        }

        // Format the date to dd-mm-yyyy
        const formattedDate = new Date(fetchedData.date).toLocaleDateString('en-GB').split('/').reverse().join('-');

        // Map fetched data to formData
        const updatedFormData = {
          purchaseOrderNo: fetchedData.purchaseOrderNo || '',
          invoiceNo: fetchedData.invoiceNo || '',
          ewayBillNo: fetchedData.ewayBillNo || '',
          date: formattedDate,
          vehicleNo: fetchedData.vehicleNo || '',
          driverName: fetchedData.driverName || '',
          inandout: fetchedData.inandout || '',
          location: fetchedData.location || '',
          uom: fetchedData.uom || '',
          color: fetchedData.color || '',
          remarks: fetchedData.remarks || '',
          manifestNo:fetchedData.manifestNo || '' ,
          schedule:fetchedData.schedule || '' ,
          pollutionboard:fetchedData. pollutionboard|| '' ,
          materialRows: fetchedData.materialRows.map(row => ({
            fuelName: row.fuelName || '',
            hsnCode: row.hsnCode || '',
            grossWeight: !isNaN(parseFloat(row.grossWeight)) ? parseFloat(row.grossWeight).toFixed(2) : '0.00',
            tareWeight:!isNaN(parseFloat(row.tareWeight)) ? parseFloat(row.tareWeight).toFixed(2) : '0.00',
            netWeight: !isNaN(parseFloat(row.netWeight)) ? parseFloat(row.netWeight).toFixed(2) : '0.00',
            density: !isNaN(parseFloat(row.density)) ? parseFloat(row.density).toFixed(2) : '0.00',
            barrelCount: !isNaN(parseFloat(row.barrelCount)) ? parseFloat(row.barrelCount).toFixed(2) : '0.00',
            litresPerBarrel: !isNaN(parseFloat(row.litresPerBarrel)) ? parseFloat(row.litresPerBarrel).toFixed(2) : '0.00',
        })),
          issave: false
        };

        handleSaveData(updatedFormData);
        setIsSaving(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error fetching data!');
        setIsSaving(true);
      });
  };

  const handleCheckboxChange = (e) => {
    setSelectedDataType(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-700 p-6 rounded-xl shadow-lg w-[50vw] h-[70vh] relative overflow-hidden">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} className="h-5 w-5 text-red-500" />
        </button>
        <h3 className="text-xl font-semibold mb-4">Saved Rows</h3>

        <div className="mb-4">
  <label className="mr-4 ">
    <input
      type="radio"
      value="inward"
      checked={selectedDataType === 'inward'}
      onChange={handleCheckboxChange}
      className=''
    />
    <span className="ml-2">Inward Data</span> {/* Added span for gap */}
  </label>
  <label className="">
    <input
      type="radio"
      value="outward"
      checked={selectedDataType === 'outward'}
      onChange={handleCheckboxChange}
    />
    <span className="ml-2">Outward Data</span> {/* Added span for gap */}
  </label>
</div>

       {/* Table for selected data type */}
       <div className="overflow-x-auto h-[calc(100%-3rem)] ">
          <div className="overflow-y-auto h-full max-h-[300px]">
            <table className="min-w-full bg-gray-600 ">
              <thead className="bg-teal-500  text-white sticky top-0">
                <tr>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium  w-16">S.No</th>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium  w-32">Date</th>
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium  w-40">Invoice No</th>
                  {/* <th className="border border-gray-400 p-3 text-left text-sm font-medium  w-40">Fuel Name</th> */}
                  <th className="border border-gray-400 p-3 text-left text-sm font-medium w-20">Select</th>
                </tr>
              </thead>
              <tbody className="text-white text-xs">
                {(selectedDataType === 'inward' ? inwardData : outwardData).length > 0 ? (
                  (selectedDataType === 'inward' ? inwardData : outwardData).map((row, index) => (
                    <tr key={row.id}>
                      <td className="border border-gray-400 p-2">{index + 1}</td>
                      <td className="border border-gray-400 p-2">{formatDate(row.date)}</td>
                      <td className="border border-gray-400 p-2">{row.invoiceNo}</td>
                      {/* <td className="border border-gray-400 p-2">{row.fuel_name}</td> */}
                      <td className="border border-gray-400 p-2">
                        <input type="checkbox" onChange={() => handleCheckboxClick(row.id)} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center p-4 text-sm text-gray-500">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveModal;
