import PurchaseIntent from "./Purchase/PI/PIrequest";
import StockTable from "./Stock/CurrentStock";
import AdminApprovalTable from "./Admin/admin";
import Dashboard from "./Dashboard/Main";
import Logsheet from "./Logsheet/Logsheet.js";
import InWardForm from "./I&O/In&out.js";
import FinanceForm from "./Expenses/expenses.js";
import QuotationSubmission from "./Purchase/Qut/QuotationSubmission.js";
import AdminQuotationReview from "./Purchase/AD/AdminReview.js";
import QuotationsTable from "./Purchase/PD/PoRise.js";
import PurchaseOrderDeatils from "./Purchase/PD/Podetails.js"
import FinanceDepartment from "./Purchase/PD/FinanceDepartment.js";



const routes = [
  { path: 'stock', component: StockTable },
  { path: 'pi', component: PurchaseIntent },
  {path:'admin_approval', component:AdminApprovalTable},
  {path:'dashboard',component:Dashboard},
  {path:'logsheet',component:Logsheet},
  {path:'in_out',component:InWardForm},
  {path:'expenses',component:FinanceForm},
  {path:'quotation',component:QuotationSubmission},
  {path:'PlantHead',component:AdminQuotationReview},
  {path:'po_form', component:QuotationsTable},
  {path:'po_details', component:PurchaseOrderDeatils},
  {path:'fin_dep', component:FinanceDepartment}
  
];

export default routes;
