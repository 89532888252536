import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ConfirmaDelete from './ConfirmDelete'; // Make sure to import your confirmation modal

const EditTankModal = ({ isOpen, onClose }) => {
  const [tankName, setTankName] = useState('');
  const [tankRadius, setTankRadius] = useState('');
  const [tankCapacity, setTankCapacity] = useState('');
  const [allTankData, setAllTankData] = useState([]);
  const [selectedTankId, setSelectedTankId] = useState('');
  const [errors, setErrors] = useState({});
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false); // State for delete confirmation
  const [selectedTankName, setSelectedTankName] = useState(''); // State for the selected tank name

  useEffect(() => {
    const fetchTankData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/stock/getTanks`);
        if (response.data && Array.isArray(response.data.data)) {
          setAllTankData(response.data.data);
        } else {
          console.error('Unexpected response format for tanks:', response.data);
        }
      } catch (error) {
        console.error('Error fetching tank data:', error);
      }
    };

    if (isOpen) {
      fetchTankData();
    }
  }, [isOpen]);

  const handleTankChange = (e) => {
    const selectedTankId = e.target.value;
    setSelectedTankId(selectedTankId);
    const selectedTank = allTankData.find(tank => tank.id === Number(selectedTankId));
    
    if (selectedTank) {
      setTankName(selectedTank.tankname);
      setTankRadius(selectedTank.tankradius);
      setTankCapacity(selectedTank.tankcapacity);
      setSelectedTankName(selectedTank.tankname);
    } else {
      setTankName('');
      setTankRadius('');
      setTankCapacity('');
      setSelectedTankName(''); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Reset errors

    const newErrors = {};
    if (!tankName) newErrors.tankname = "Tank name is required";
    if (!tankRadius || tankRadius <= 0) newErrors.tankradius = "Tank radius must be greater than 0";
    if (!tankCapacity || tankCapacity <= 0) newErrors.tankcapacity = "Tank capacity must be greater than 0";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent submission if there are validation errors
    }

    try {
      await axios.put(`${config.apiUrl}/stock/storagetank/${selectedTankId}`, {
        tankname: tankName,
        tankradius: tankRadius,
        tankcapacity: tankCapacity,
      });
      onClose();
      toast.success('Tank updated successfully!');
    } catch (error) {
      console.error('Error updating tank:', error);
      toast.error('Failed to update tank. Please try again.');
    }
  };

  const handleDelete = () => {
    setConfirmDeleteOpen(true); // Open the confirmation modal
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${config.apiUrl}/stock/storagetankdelete/${selectedTankId}`);
      setConfirmDeleteOpen(false); // Close the confirmation modal
      onClose();
      toast.success('Tank deleted successfully!');
    } catch (error) {
      console.error('Error deleting tank:', error);
      toast.error('Failed to delete tank. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="p-6 rounded-md w-full max-w-lg bg-white shadow-lg transform transition-all relative text-black">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b border-gray-300 pb-2">Edit Tank</h2>
        <button onClick={onClose} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block mb-1">Select Tank</label>
              <select
                value={selectedTankId}
                onChange={handleTankChange}
                className="border form-input w-full rounded-md text-sm p-2"
                required
              >
                <option value="" disabled>Select a tank</option>
                {Array.isArray(allTankData) && allTankData.map(tank => (
                  <option key={tank.id} value={tank.id}>
                    {tank.tankname}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-gray-700 mb-1 text-sm">
                <span className="text-red-500">*</span>Tank Name</label>
              <input
                type="text"
                value={tankName}
                onChange={(e) => setTankName(e.target.value)}
                className="border form-input w-full rounded-md text-sm p-2"
                placeholder='Enter Tank Name'
                required
              />
              {errors.tankname && <p className="text-red-500 text-xs">{errors.tankname}</p>}
            </div>
            <div>
              <label className="block text-gray-700 mb-1 text-sm">
                <span className="text-red-500">*</span>Tank Radius</label>
              <input
                type="number"
                value={tankRadius}
                onChange={(e) => setTankRadius(e.target.value)}
                className="border form-input w-full rounded-md text-sm p-2"
                placeholder='Enter Tank Radius'
                required
                step="0.01"
              />
              {errors.tankradius && <p className="text-red-500 text-xs">{errors.tankradius}</p>}
            </div>
            <div>
              <label className="block text-gray-700 mb-1 text-sm">
                <span className="text-red-500">*</span>Tank Capacity</label>
              <input
                type="number"
                value={tankCapacity}
                onChange={(e) => setTankCapacity(e.target.value)}
                className="border form-input w-full rounded-md text-sm p-2"
                placeholder='Enter tank capacity'
                required
                step="0.01"
              />
              {errors.tankcapacity && <p className="text-red-500 text-xs">{errors.tankcapacity}</p>}
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="px-3 py-1.5 text-sm bg-rose-500 hover:bg-rose-600 transition text-white rounded-md"
            >
              Save 
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="ml-2 px-3 py-1.5 text-sm bg-red-500 hover:bg-red-600 transition text-white rounded-md"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
      <ConfirmaDelete 
        isOpen={isConfirmDeleteOpen} 
        message={`Are you sure you want to delete ${selectedTankName} Tank?`}
        onConfirm={confirmDelete} 
        onCancel={() => setConfirmDeleteOpen(false)} 
      />
    </div>
  );
};

export default EditTankModal;
