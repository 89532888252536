import React, { useState } from 'react';
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/24/solid'; 
import ConfirmaDelete from './ConfirmDelete'
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StockList = ({ filteredStockData, handleEditClick, fetchStockData }) => {
  console.log(filteredStockData);
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  const handleDelete = async () => {
    if (!selectedItem || !selectedItem.id) {
      toast.error('No item selected or item ID is missing');
      return;
    }
    
    try {
      await axios.delete(`${config.apiUrl}/stock/deletestock/${selectedItem.id}`);
      toast.success('Deleted successfully!');
      fetchStockData();
      closeModal();
    } catch (error) {
      console.error('Error deleting entry:', error);
      alert('Failed to delete entry');
    }
  };

  return (
    <>
    <div className="overflow-y-auto max-h-[85vh]">
      <table className="items-center w-full divide-y ">
        <thead className="sticky top-0  bg-teal-600 text-gray-100">
          <tr>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">S.No</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Material</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Material Type</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Tanker Name</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Capacity (KL)</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Radius</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Stock (KL)</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Last Update</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Actions</th>
            <th className="px-2 align-middle  py-2 text-xs uppercase  whitespace-nowrap font-semibold text-center">Delete</th>
          </tr>
        </thead>
      
        <tbody className='bg-gray-700'>
          {filteredStockData.map((item, index) => (
            <tr key={index} className="text-white font-semibold">
              <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-center p-4">{index + 1}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.oilname}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.materialtype}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.tankname}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.capacity || 0}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.tankradius || 0}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.currentquantity}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{item.lastupdateddate.split(' ')[0]}</td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                <button onClick={() => handleEditClick(item)} className="text-blue-500 hover:underline">Edit</button>
              </td>
              <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                <button onClick={() => openModal(item)} className="text-red-500 hover:underline">
                  <TrashIcon className="h-5 w-5 inline-block" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      
      {/* Modal for confirmation */}
      <ConfirmaDelete
        isOpen={modalIsOpen}
        message={`Are you sure you want to delete ${selectedItem?.oilname}?`}
        onConfirm={handleDelete}
        onCancel={closeModal}
      />
    </>
  );
};

export default StockList;
