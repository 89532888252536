import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import SkeletonLoader from '../../SkeletonLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FinanceDepartment = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [paymentStatuses, setPaymentStatuses] = useState([]);
  const [expandedOrders, setExpandedOrders] = useState(new Set());
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [loading, setLoading] = useState(true);

  // Filter states
  const [poNumber, setPoNumber] = useState('');
  const [piNumber, setPiNumber] = useState('');
  const [date, setDate] = useState('');

  // Fetch payment statuses
  const fetchPaymentStatuses = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/finance/status-from-potable`);
      setPaymentStatuses(response.data);
    } catch (error) {
      toast.error('Error fetching payment statuses');
    }
  };

  // Toggle expand order
  const toggleExpandOrder = (orderId) => {
    setExpandedOrders((prev) => {
      const newExpandedOrders = new Set(prev);
      if (newExpandedOrders.has(orderId)) {
        newExpandedOrders.delete(orderId);
      } else {
        newExpandedOrders.add(orderId);
      }
      return newExpandedOrders;
    });
  };

  // Fetch purchase orders
  const fetchPurchaseOrders = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/po/final-po`);
      setPurchaseOrders(response.data);
      setFilteredOrders(response.data); // Default filtered orders
    } catch (error) {
      // Do not show a toast error, handle it silently or log the error
      console.error('Error fetching purchase orders', error);  // Log the error for debugging
    }
  };

  // Merge both datasets by matching PO ID
  const mergeData = () => {
    const merged = purchaseOrders.map((order) => {
      const paymentStatus = paymentStatuses.find((status) => status.po_id === order.id);
      return {
        ...order,
        payment_status: paymentStatus ? paymentStatus.payment_status : 'Pending',
      };
    });
    setFilteredOrders(merged);
  };

  // Fetch both datasets on component mount
  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchPurchaseOrders(), fetchPaymentStatuses()]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (purchaseOrders.length && paymentStatuses.length) {
      mergeData(); // Merge only after both datasets are loaded
    }
  }, [purchaseOrders, paymentStatuses]);

  // Filter logic
  const handleFilter = () => {
    if (poNumber.trim() === '' && piNumber.trim() === '' && date === '') {
      setFilteredOrders(purchaseOrders); // Show all orders if no filter is applied
      return; // Exit the function early
    }
    const filtered = purchaseOrders.filter((order) => {
      const matchesPoNumber =
        poNumber.trim() === '' || order.Po_No.toLowerCase().includes(poNumber.trim().toLowerCase());
      const matchesPiNumber =
        piNumber.trim() === '' || order.PiNo?.toLowerCase().includes(piNumber.trim().toLowerCase());
      const matchesDate =
        date === '' ||
        new Date(order.created_at).toLocaleDateString() ===
          new Date(date).toLocaleDateString();
      return matchesPoNumber && matchesPiNumber && matchesDate;
    });
    setFilteredOrders(filtered);
  };
  

  // Handle payment modal logic
  const openPaymentModal = (order) => {
    setSelectedOrder(order);
    setPaymentAmount('');
    setIsPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setSelectedOrder(null);
    setIsPaymentModalOpen(false);
  };

  const handlePaymentSubmit = async () => {
    if (!paymentAmount || parseFloat(paymentAmount) <= 0) {
      toast.error('Please enter a valid payment amount.');
      return;
    }

    if (parseFloat(paymentAmount) > selectedOrder.order_total) {
      toast.error('Payment cannot exceed the order total!');
      return;
    }

    try {
      await axios.post(`${config.apiUrl}/finance/payments`, {
        po_id: selectedOrder.id,
        payment_amount: parseFloat(paymentAmount),
      });

      toast.success('Payment submitted successfully!');
      closePaymentModal();
      fetchData();
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error processing payment');
    }
  };

  if (loading) {
    return (
      <SkeletonLoader height={100} count={10} />
    );
  }

  return (
    <div className="container p-6 bg-gray-900 min-h-screen">
      <h1 className="text-2xl font-montserrat font-bold text-gray-100 mb-4 text-center">
      Billing
      </h1>

      {/* Filter Section */}
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center">
        <input
          type="text"
          placeholder="Filter by PO Number"
          value={poNumber}
          onChange={(e) => setPoNumber(e.target.value)}
          className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300"
        />
        <input
          type="text"
          placeholder="Filter by PI Number"
          value={piNumber}
          onChange={(e) => setPiNumber(e.target.value)}
          className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300"
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300"
        />
        <button
          onClick={handleFilter}
          className="px-6 py-2 bg-teal-600 text-white rounded-lg font-semibold hover:bg-teal-700"
        >
          Apply Filter
        </button>
      </div>

      {filteredOrders.length === 0 ? (
        <div className="text-center p-3 text-gray-500">
          No purchase orders found.
        </div>
      ) : (
        filteredOrders.map((order) => (
          <div key={order.id} className="bg-gray-800 shadow-lg rounded-lg p-2 mb-4 text-gray-100">
            <div className="flex justify-between items-center">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={expandedOrders.has(order.id)}
                onChange={() => toggleExpandOrder(order.id)}
                className="w-5 h-5"
              />
              <h2 className="text-lg font-bold text-teal-400 ml-2">PO: {order.Po_No}</h2>
              </div>
              <button
                onClick={() => openPaymentModal(order)}
                className="px-4 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm"
              >
                Make Payment
              </button>
            </div>
            <p className="text-sm text-gray-400">
              <strong>Total Amount:</strong> ₹{order.order_total}
            </p>
            <p className="text-sm text-gray-400">
              <strong>Payment Status:</strong> {order.payment_status}
            </p>
            {expandedOrders.has(order.id) && (
  <div className="mt-4 border-t border-gray-700 pt-4">
    {/* Left Side: PI No and Date */}
    <div className="flex flex-col md:flex-row justify-between">
      <div className="md:w-1/2">
        <p className="text-sm text-gray-400">
          <strong>PI No:</strong> {order.PiNo}
        </p>
        <p className="text-sm text-gray-400">
          <strong>Date:</strong> {new Date(order.created_at).toLocaleString()}
        </p>
      </div>

      {/* Right Side: Material, Quantity, and HSN Code */}
      <div className="md:w-1/2">
        {order.items.map(item => (
          <div key={item.id} className='ml-3'>
            <p className="text-sm text-gray-400">
              <strong>Material:</strong> {item.description}
            </p>
            <p className="text-sm text-gray-400">
              <strong>Quantity:</strong> {item.qty} {item.uom}
            </p>
            <p className="text-sm text-gray-400">
              <strong>HSN Code:</strong> {item.hsn_code}
            </p>
          </div>
        ))}
      </div>
    </div>

    {/* Billing and Shipping Information */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
      <div>
        <h3 className="text-lg font-semibold text-teal-300">Billing Information</h3>
        <p className="text-sm text-gray-400">Name: {order.bill_to_name}</p>
        <p className="text-sm text-gray-400">Address: {order.bill_to_address}</p>
   
      </div>
      <div>
        <h3 className="text-lg font-semibold text-teal-300">Shipping Information</h3>
        <p className="text-sm text-gray-400">Name: {order.ship_to_name}</p>
        <p className="text-sm text-gray-400">Address: {order.ship_to_address}</p>

      </div>
    </div>
  </div>
)}

          </div>
        ))
      )}

      {isPaymentModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold text-teal-400 mb-4">
              Make Payment for PO: {selectedOrder.Po_No}
            </h2>
            <input
              type="number"
              placeholder="Enter Amount"
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={closePaymentModal}
                className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={handlePaymentSubmit}
                className="px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700"
              >
                Submit Payment
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinanceDepartment;
