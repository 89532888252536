import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import SkeletonLoader from '../../SkeletonLoader';

const PurchaseOrderDetails = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Loading state

  
  // Filter states
  const [poNumber, setPoNumber] = useState('');
  const [piNumber, setPiNumber] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    const fetchPurchaseOrders = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.get(`${config.apiUrl}/po/final-po`);
        setPurchaseOrders(response.data);
        setFilteredOrders(response.data); // Set initially to all orders
      } catch (err) {
        setError('Error fetching purchase orders');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchPurchaseOrders();
  }, []);

  const handleFilter = () => {
    const filtered = purchaseOrders.filter((order) => {
      const matchesPoNumber = poNumber === '' || order.Po_No.includes(poNumber);
      const matchesPiNumber = piNumber === '' || order.PiNo.includes(piNumber);
      const matchesDate = date === '' || new Date(order.created_at).toLocaleDateString() === new Date(date).toLocaleDateString();

      return matchesPoNumber && matchesPiNumber && matchesDate;
    });
    setFilteredOrders(filtered);
  };

  if (loading) {
    return <div><SkeletonLoader height={100} count={10} /></div>; 
  }

  return (
    <div className="container  p-6  bg-gray-900 min-h-screen">
      <h1 className="text-2xl font-montserrat font-bold text-gray-100 mb-4 text-center">Purchase Orders</h1>

      {/* Filter Section */}
      <div className="mb-4 flex flex-col md:flex-row gap-4 items-center">
        <input
          type="text"
          placeholder="Filter by PO Number"
          value={poNumber}
          onChange={(e) => setPoNumber(e.target.value)}
          className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300"
        />
        <input
          type="text"
          placeholder="Filter by PI Number"
          value={piNumber}
          onChange={(e) => setPiNumber(e.target.value)}
          className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300"
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="px-4 py-2 rounded-lg bg-gray-800 text-gray-300"
          
        />
        <button
          onClick={handleFilter}
          className="px-6 py-2 bg-teal-600 text-white rounded-lg font-semibold hover:bg-teal-700"
        >
          Apply Filter
        </button>
      </div>

      {filteredOrders.length === 0 ? ( 
        <div className="text-center p-3 text-gray-500">No purchase orders found.</div>
        ) : (
        filteredOrders.map((order) => (
          <div key={order.id} className="bg-gray-800 shadow-lg rounded-lg p-4 md:p-6 mb-8 text-gray-100">
            <div className="border-b pb-4 mb-4 border-gray-700">
              <h2 className="text-xl font-bold text-teal-400 mb-2">PO: {order.Po_No}</h2>
              <p className="text-sm text-gray-400"><strong>PI Number:</strong> {order.PiNo}</p>
              <p className="text-sm text-gray-400"><strong>Total Quantity:</strong> {order.total_qty}</p>
              <p className="text-sm text-gray-400"><strong>Amount:</strong> ₹{order.total_amount}</p>
              <p className="text-sm text-gray-400"><strong>Tax:</strong> ₹{order.total_tax}</p>
              <p className="text-sm text-gray-400"><strong>Total Amount:</strong> ₹{order.order_total}</p>
              <p className="text-sm text-gray-400"><strong>Created At:</strong> {new Date(order.created_at).toLocaleString()}</p>
            </div>

            {/* Billing and Shipping Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="border-b pb-4 mb-4 border-gray-700">
                <h3 className="text-xl font-semibold text-teal-300 mb-2">Billing Information</h3>
                <p className="text-sm text-gray-400"><strong>Bill To:</strong> {order.bill_to_name}</p>
                <p className="text-sm text-gray-400"><strong>Address:</strong> {order.bill_to_address}</p>
                <p className="text-sm text-gray-400"><strong>GST:</strong> {order.bill_to_gst}</p>
                <p className="text-sm text-gray-400"><strong>Email:</strong> {order.bill_to_email}</p>
              </div>

              <div className="border-b pb-4 mb-4 border-gray-700">
                <h3 className="text-xl font-semibold text-teal-300 mb-2">Shipping Information</h3>
                <p className="text-sm text-gray-400"><strong>Ship To:</strong> {order.ship_to_name}</p>
                <p className="text-sm text-gray-400"><strong>Address:</strong> {order.ship_to_address}</p>
                <p className="text-sm text-gray-400"><strong>GST:</strong> {order.ship_to_gst}</p>
                <p className="text-sm text-gray-400"><strong>Email:</strong> {order.ship_to_email}</p>
              </div>
            </div>

            {/* Order Items */}
            <div>
              <h3 className="text-xl font-semibold text-teal-300 mb-4">Order Items</h3>
              {order.items.length > 0 ? (
                <table className="min-w-full bg-gray-900 border-collapse">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">Description</th>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">HSN Code</th>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">Quantity</th>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">UOM</th>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">Item Rate</th>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">Tax %</th>
                      <th className="py-2 px-4 bg-teal-600 text-left text-gray-100 font-bold text-sm">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map((item) => (
                      <tr key={item.id} className="border-b border-gray-700">
                        <td className="py-2 px-4 text-gray-300 text-sm">{item.description}</td>
                        <td className="py-2 px-4 text-gray-300 text-sm">{item.hsn_code}</td>
                        <td className="py-2 px-4 text-gray-300 text-sm">{item.qty}</td>
                        <td className="py-2 px-4 text-gray-300 text-sm">{item.uom}</td>
                        <td className="py-2 px-4 text-gray-300 text-sm">₹{item.item_rate}</td>
                        <td className="py-2 px-4 text-gray-300 text-sm">{item.tax_percent}%</td>
                        <td className="py-2 px-4 text-gray-300 text-sm">₹{item.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-gray-500 text-center mt-6 font-light">No items found for this order.</div>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PurchaseOrderDetails;
