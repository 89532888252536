import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BarrelModal = ({ isOpen, onClose, fetchBarrelStock }) => {
    const [material, setMaterial] = useState('');
    const [barrelCount, setBarrelCount] = useState('');
    const [litresPerBarrel, setLitresPerBarrel] = useState('');
    const [qty, setQty] = useState(''); // New state for quantity
    const [yardState, setYardState] = useState(''); // New state for yard name
    const [date, setDate] = useState('');

  // Calculate qty when barrelCount or litresPerBarrel changes
useEffect(() => {
    const calculatedQty = parseInt(barrelCount || 0) * parseFloat(litresPerBarrel || 0);
    setQty(calculatedQty.toFixed(2));  // Store qty as a formatted decimal value
}, [barrelCount, litresPerBarrel]);

    const handleAddBarrelStock = async () => {
        try {
            await axios.post(`${config.apiUrl}/stock/barrel-stock`, {
                Material: material,
                barrelCount: parseInt(barrelCount),
                Litres_Per_Barrel: parseFloat(litresPerBarrel),
                qty: parseFloat(qty),
                yard: yardState,
                Date: date,
            });
            toast.success('Barrel added successfully');
            // Reset form after submission
            setMaterial('');
            setBarrelCount('');
            setLitresPerBarrel('');
            setQty('');
            setYardState('');
            setDate('');
            fetchBarrelStock(); // Refresh the barrel stock list
        } catch (error) {
            toast.error('Error adding barrel stock!');
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchBarrelStock();
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white text-gray-500 relative rounded-lg shadow-lg p-6 w-11/12 max-w-lg">
                <button onClick={onClose} className="absolute top-2 right-2">
                    <FontAwesomeIcon className="text-red-500" icon={faTimes} />
                </button>
                <h2 className="text-xl font-semibold mb-4">Add Barrel Stock</h2>
    
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Material
                    </label>
                    <input
                        type="text"
                        value={material}
                        onChange={(e) => setMaterial(e.target.value)}
                        placeholder="Material"
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
    
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Barrel Count
                    </label>
                    <input
                        type="number"
                        value={barrelCount}
                        onChange={(e) => setBarrelCount(e.target.value)}
                        placeholder="Barrel Count"
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
    
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Litres Per Barrel
                    </label>
                    <input
                        type="number"
                        value={litresPerBarrel}
                        onChange={(e) => setLitresPerBarrel(e.target.value)}
                        placeholder="Litres Per Barrel"
                        step="0.01"
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
    
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Quantity (calculated)
                    </label>
                    <input
                        type="number"
                        value={qty}
                        readOnly
                        placeholder="Quantity (calculated)"
                        className="border border-gray-300 rounded-md p-2 w-full bg-gray-100"
                    />
                </div>
    
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Yard Name
                    </label>
                    <input
                        type="text"
                        value={yardState}
                        onChange={(e) => setYardState(e.target.value)}
                        placeholder="Yard Name"
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
    
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Date
                    </label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="border border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
    
                <div className="flex justify-end mt-6">
                    <button
                        onClick={handleAddBarrelStock}
                        className="text-sm px-3 py-1.5 bg-rose-500 hover:bg-rose-600 text-white rounded-md transition"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
    
};

export default BarrelModal;
