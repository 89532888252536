import React, { useState,useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StockAddModal = ({ formData, setFormData, setAddingStock, fetchStockData, tankOptions, stockData }) => {
  const [errors, setErrors] = useState({});
  const [oilOptions, setOilOptions] = useState([]);
   useEffect(() => {
    const fetchOilNames = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/stock/getoilnames`);
         // Add 'N/A' option to the oil names list
         setOilOptions(['N/A', ...response.data.map(item => item.oilname)]);
      } catch (error) {
        console.error('Failed to fetch oil names:', error);
      }
    };

    fetchOilNames();
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.tankname) newErrors.tankname = 'Tank name is required.';
    if (!formData.oilname) newErrors.oilname = 'Oil name is required.';
    if (formData.currentquantity === undefined || formData.currentquantity === null || isNaN(formData.currentquantity)) {
      newErrors.currentquantity = 'Current quantity is required and must be a valid number.';
    } else if (formData.currentquantity < 0) {
      newErrors.currentquantity = 'Current quantity must be a non-negative value.';
    }
    if (!formData.materialtype) newErrors.materialtype = 'Material type is required.';

    // Validate if current quantity is higher than tank capacity
    if (formData.capacity && formData.currentquantity > parseFloat(formData.capacity)) {
      newErrors.currentquantity = 'Current quantity cannot be higher than the tank capacity.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isTankContainsOil = (tankName) => {
    // Find the stock entry for the given tankName
    const stockEntry = stockData.find(stock => stock.tankname === tankName);
    // Return true if the stock entry exists and has an oilname
    return stockEntry && stockEntry.oilname;
  };
   // Prepare tank options with an 'containsOil' flag
   const preparedOptions = tankOptions.map(tank => ({
    ...tank,
    containsOil: isTankContainsOil(tank.tankname),
  }));
  const handleTankChange = async (event) => {
    const typedTankName = event.target.value;
  
    if (isTankContainsOil(typedTankName)) {
      // Find the specific stock entry for the given tank name
      const stockEntry = stockData.find(stock => stock.tankname === typedTankName);
      const currentOilName = stockEntry ? stockEntry.oilname : 'oil';
      toast.error(`The tank "${typedTankName}" already contains ${currentOilName}. Please select a different tank.`);
      setFormData({
        ...formData,
        tankname: '',
        radius: '',
        capacity: '',
      });
      return;
    }
  
    setFormData(prevData => ({
      ...prevData,
      tankname: typedTankName,
    }));
  
    if (typedTankName) {
      try {
        const response = await axios.get(`${config.apiUrl}/stock/getTankDetails`, {
          params: { tankname: typedTankName },
        });
        if (response.data && response.data.data) {
          console.log(response.data.data);
          
          setFormData(prevData => ({
            ...prevData,
            radius: response.data.data.tankradius,
            capacity: response.data.data.tankcapacity,
          }));
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching tank details:', error);
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        radius: '',
        capacity: '',
      }));
    }
  };
  
  const handleFormChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'currentquantity') {
      // Ensure positive number with up to two decimal places
      const cleanedValue = value
        .replace(/[^0-9.]/g, '') // Remove non-numeric and non-decimal characters
        .replace(/(\..*)\..*/g, '$1') // Ensure only one decimal point
        .replace(/^0+([1-9])/, '$1') // Remove leading zeros except for 0
        .replace(/(\.\d{2})\d+/g, '$1'); // Allow only up to two decimal places
  
      // Convert cleaned value to float, allow 0
      setFormData(prevData => ({
        ...prevData,
        [name]: cleanedValue === '' ? '0' : cleanedValue, // Set to '0' if empty
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  
    // Clear the error message for the current field
    if (name === 'currentquantity' || name === 'oilname') {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };
  
  
  const handleMaterialTypeSelect = (event) => {
    setFormData(prevData => ({
      ...prevData,
      materialtype: event.target.value,
    }));
  };
  

  const handleAddStock = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(`${config.apiUrl}/stock/addStock`, formData);
      if (response.data.success) {
        toast.success('Stock added successfully!');
        fetchStockData();
        setAddingStock(false);
      } else {
        toast.error('Failed to add stock. Please try again.');
      }
    } catch (error) {
      console.error('Error occurred:', error); // Debugging line
      toast.error('An error occurred while adding stock. Please try again.');
    }
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="p-6 rounded-md w-full max-w-lg shadow-lg bg-white relative text-black">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b border-gray-300 pb-2">Add Stock</h2>
        <button  onClick={() => setAddingStock(false)} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <form onSubmit={handleAddStock}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Tank Name
              </label>
              <select
          name="tankname"
          value={formData.tankname}
          onChange={handleTankChange}
          className={`form-input w-full border ${errors.tankname ? 'border-red-500' : 'border-gray-300'} rounded-md bg-white`}
        >
          <option value="" className="text-gray-500">Select Tank</option>
          {preparedOptions.map(tank => (
            <option
              key={tank.tankname}
              value={tank.tankname}
              className={`${
                tank.containsOil ? ' text-red-500' : ' text-green-500'
              }`}
            >
              {tank.tankname}
            </option>
          ))}
        </select>
        {errors.tankname && <p className="text-red-500 text-sm mt-1">{errors.tankname}</p>}
      </div>

            <div>
            <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Oil Name
              </label>
              <select
                name="oilname"
                value={formData.oilname}
                onChange={handleFormChange}
                className={`form-input w-full text-sm border ${errors.oilname ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                required
                >
                <option value="">Select Oil</option>
                {oilOptions.length === 0 ? (
                  <option value="" disabled>No oils available</option>
                ) : (
                  oilOptions.map((oil, index) => (
                    <option key={index} value={oil}>
                      {oil}
                    </option>
                  ))
                )}
              </select>
              {errors.oilname && <p className="text-red-500 text-sm mt-1">{errors.oilname}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Current Quantity
              </label>
              <input
                type="number"
                name="currentquantity"
                value={formData.currentquantity || ''}
                onChange={handleFormChange}
                className={`form-input w-full border ${errors.currentquantity ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                placeholder="Enter current quantity"
              
              />
              {errors.currentquantity && <p className="text-red-500 text-sm">{errors.currentquantity}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">
                <span className="text-red-500">*</span> Material Type
              </label>
              <select
                name="materialtype"
                value={formData.materialtype}
                onChange={handleMaterialTypeSelect}
                className={`form-input w-full border text-sm ${errors.materialtype ? 'border-red-500' : 'border-gray-300'} rounded-md bg-white text-gray-800 focus:outline-none focus:ring focus:ring-blue-300`}
                required
              >
                  <option value="">Select Material Type</option>
                  <option value="N/A">N/A</option>
              <option value="rawmaterial">Raw Material</option>
              <option value="productaftermixing">Product After Mixing</option>
              <option value="product">Product</option>
              <option value="solvent">Solvent</option>
              <option value="I/P">I/P</option>
                 
              </select>
              {errors.materialtype && <p className="text-red-500 text-sm mt-1">{errors.materialtype}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">Radius</label>
              <input
                type="text"
                name="radius"
                value={formData.radius}
                onChange={handleFormChange}
                className="form-input w-full border border-gray-300 rounded-md"
                placeholder="Enter radius"
                readOnly
              />
            </div>

            <div>
              <label className="block text-gray-700 mb-1">Capacity</label>
              <input
                type="text"
                name="capacity"
                value={formData.capacity}
                onChange={handleFormChange}
                className="form-input w-full border border-gray-300 rounded-md"
                placeholder="Enter capacity"
                readOnly
              />
            </div>
          </div>

          <div className="flex justify-end mt-6">
           
            <button
              type="submit"
              className=" text-sm px-3 py-1.5 bg-rose-500 hover:bg-rose-600 text-white rounded-md  transition"
            >
              Add Stock
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StockAddModal;
