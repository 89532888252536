import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Download = ({ closedown, stockData }) => {
  const [format, setFormat] = useState('');

  // Utility function to get current date in dd-mm-yyyy format
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDownload = () => {
    if (format === 'pdf') {
      downloadPDF();
    } else if (format === 'excel') {
      downloadExcel();
    } else {
      toast.error('Please select a format to download.');
    }
    closedown();
  };

 // PDF Download function
const downloadPDF = () => {
  const currentDate = getCurrentDate();
  const doc = new jsPDF({
      orientation: 'landscape', // Better fit for wide tables
      unit: 'pt', // pt for precision
      format: 'A4',
  });

  // Title of the document
  doc.setFontSize(16);
  doc.text('Stock List', 40, 40);

  // Add the download date below the title (only on the first page)
  doc.setFontSize(10); // Smaller font for date

  const rowsPerPage = 15; // Define how many rows should be on each page

  // Split the stockData into pages of 15 rows each
  const pages = [];
  for (let i = 0; i < stockData.length; i += rowsPerPage) {
      pages.push(stockData.slice(i, i + rowsPerPage));
  }

  // Loop through pages and generate tables for each
  pages.forEach((pageData, pageIndex) => {
      // If this is not the first page, add a new page
      if (pageIndex !== 0) {
          doc.addPage();
      }

      // Add the table for this page
      doc.autoTable({
          startY: pageIndex === 0 ? 70 : 40, // Starting position after the title and date (only for the first page)
          headStyles: { fillColor: [0, 123, 255], textColor: 255, fontSize: 10 },
          bodyStyles: { fontSize: 10, cellPadding: 6, valign: 'middle' },
          margin: { top: 50, right: 40, bottom: 50, left: 40 },
          theme: 'striped',
          head: [['S.No', 'Material', 'Material Type', 'Tanker Name', 'Capacity (KL)', 'Radius', 'Stock (KL)', 'Last Update']],
          body: pageData.map((item, index) => [
              rowsPerPage * pageIndex + (index + 1), // Correct numbering across pages
              item.oilname,
              item.materialtype,
              item.tankname,
              item.capacity || 0,
              item.tankradius || 0,
              item.currentquantity,
              item.lastupdateddate.split(' ')[0],
          ]),
          styles: { overflow: 'linebreak' },
          showHead: 'everyPage', // Ensure table header repeats on new page
      });
  });

  doc.save(`StockList_${currentDate}.pdf`);
  toast.success('PDF downloaded successfully');
};


  // Excel Download function
  const downloadExcel = async () => {
    const currentDate = getCurrentDate();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Stock List');

    // Add header row with styles
    worksheet.addRow(['S.No', 'Material', 'Material Type', 'Tanker Name', 'Capacity (KL)', 'Radius', 'Stock (KL)', 'Last Update']);
    worksheet.getRow(1).font = { bold: true, size: 12 };
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };

    // Add a row showing the download date
    worksheet.addRow([]);
    worksheet.getRow(2).font = { italic: true }; // Stylize the download date row

    // Add data rows with formatting
    stockData.forEach((item, index) => {
      const row = worksheet.addRow([
        index + 1,
        item.oilname,
        item.materialtype,
        item.tankname,
        item.capacity || 0,
        item.tankradius || 0,
        item.currentquantity,
        item.lastupdateddate.split(' ')[0],
      ]);
      row.alignment = { vertical: 'middle', horizontal: 'left' }; // Align data properly
    });

    // Set column widths for neatness
    worksheet.columns = [
      { width: 10 },  // S.No
      { width: 20 },  // Material
      { width: 20 },  // Material Type
      { width: 25 },  // Tanker Name
      { width: 15 },  // Capacity
      { width: 15 },  // Radius
      { width: 15 },  // Stock
      { width: 20 },  // Last Update
    ];

    // Save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `StockList_${currentDate}.xlsx`;
    link.click();

    toast.success('Excel downloaded successfully');
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-2xl shadow-lg max-w-sm w-full relative">
        <h2 className="text-xl  text-gray-700 font-bold mb-4">Download Stock Data</h2>
        <button onClick={closedown} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <select
          value={format}
          onChange={(e) => setFormat(e.target.value)}
          className="w-full px-3 py-2 mb-4 rounded-lg border border-gray-500 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-blue-50"
        >
          <option value="" disabled>Select Format</option>
          <option value="pdf">PDF</option>
          <option value="excel">Excel</option>
        </select>
       
        <div className="flex justify-end">
          <button
            onClick={handleDownload}
            className={`py-1.5 px-3 rounded-lg text-white text-sm
                ${!format ? 'bg-gray-400 cursor-not-allowed' : 'bg-rose-500 hover:bg-rose-600 text-white'}`}
            disabled={!format}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default Download;
