import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { FaFileAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import icons from react-icons
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from '@headlessui/react'; // For the modal

const AdminQuotationReview = () => {
    const [quotations, setQuotations] = useState([]);
    const [selectedFile, setSelectedFile] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
    const [currentFiles, setCurrentFiles] = useState([]); // State to store files for the current quotation
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Track current slide index

    const fetchQuotations = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/purchasedep/non-approval-quotations`);
            setQuotations(response.data);
        } catch (error) {
            console.error('Error fetching quotations:', error);
        }
    };

    useEffect(() => {
        fetchQuotations();
    }, []);

    // Handle selecting a file for a specific quotation
    const handleSelectFile = (quotationId, fileName) => {
        setSelectedFile((prevSelectedFile) => {
            const updatedSelectedFile = {
                ...prevSelectedFile,
                [quotationId]: fileName,
            };
            return updatedSelectedFile;
        });
    };

    // Handle opening the modal and showing files for a specific quotation
    const handleShowFiles = (files) => {
        setCurrentFiles(files);
        setIsModalOpen(true);
        setCurrentSlideIndex(0); // Reset to the first slide when modal opens
    };

    // Navigate forward in the file slider
    const handleNextSlide = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % currentFiles.length);
    };

    // Navigate backward in the file slider
    const handlePrevSlide = () => {
        setCurrentSlideIndex((prevIndex) => (prevIndex - 1 + currentFiles.length) % currentFiles.length);
    };

    // Handle sending selected file to the purchase team
    const handleSendToPurchase = async () => {
        if (!Object.keys(selectedFile).length) {
            toast.error('Please select a file for each quotation to send to the purchase team.');
            return;
        }

        try {
            for (const quotationId in selectedFile) {
                const fileName = selectedFile[quotationId];

                await axios.put(`${config.apiUrl}/purchasedep/update-quotation`, {
                    quotationId,
                    fileName,
                });

                await axios.patch(`${config.apiUrl}/purchase/admin-approval-flag-update`, {
                    quotationId,
                });

                console.log(`Sent: ${fileName} for Quotation ID: ${quotationId}`);
            }

            toast.success('Selected files sent to the purchase team successfully');
            setSelectedFile({});
            fetchQuotations();
        } catch (error) {
            console.error('Error sending file:', error);
            toast.error('Failed to send file to the purchase team');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 p-6 flex flex-col items-center">
        <h2 className="text-xl font-bold text-center mb-4 text-white font-montserrat">
            Plant Head Quotation Review
        </h2>
        <div className="w-full shadow-lg rounded-lg overflow-hidden">
            <div className="max-h-[80vh] overflow-y-auto">
                <table className="min-w-full bg-gray-800">
                    <thead className="bg-teal-600 text-gray-100 sticky top-0 z-10">
                        <tr>
                            <th className="px-3 py-2 text-sm font-medium uppercase tracking-wider">PI.No</th>
                            <th className="px-3 py-2 text-sm font-medium uppercase tracking-wider">Raw Material</th>
                            <th className="px-3 py-2 text-sm font-medium uppercase tracking-wider">Quantity</th>
                            <th className="px-3 py-2 text-sm font-medium uppercase tracking-wider">Files</th>
                            <th className="text-left px-3 py-2 text-sm font-medium uppercase tracking-wider">Select File</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-500 text-white text-center text-sm">
                        {quotations.length > 0 ? (
                            quotations.map((quotation) => {
                                const fileNames = JSON.parse(quotation.file_names);
                                const filePaths = JSON.parse(quotation.file_paths);
    
                                return (
                                    <tr key={quotation.ID} className="hover:bg-gray-700 transition">
                                        <td className="px-3 py-2">{quotation.PiNo}</td>
                                        <td className="px-3 py-2">{quotation.RawMaterial}</td>
                                        <td className="px-3 py-2">{quotation.Qty}</td>
                                        <td className="px-3 py-2">
                                            <div className="flex items-center justify-center">
                                                <FaFileAlt
                                                    className="text-[#438FD1] cursor-pointer mr-2"
                                                    size={20}
                                                    onClick={() => handleShowFiles(filePaths)}
                                                />
                                            </div>
                                        </td>
                                        <td className="px-3 py-2">
                                            <div className="flex flex-col items-start">
                                                {fileNames.map((fileName, index) => {
                                                    const isSelected = selectedFile[quotation.ID] === fileName;
                                                    return (
                                                        <div key={index} className="flex items-center mb-1">
                                                            <input
                                                                type="radio"
                                                                checked={isSelected}
                                                                onChange={() => handleSelectFile(quotation.ID, fileName)}
                                                                className="form-radio text-blue-600 mr-2"
                                                            />
                                                            <label className="cursor-pointer">{fileName}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center p-5 text-white">Quotation Details Not Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    
        {/* Modal for showing files */}
        {isModalOpen && (
            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className="fixed z-10 bg-gray-800 bg-opacity-75 inset-0 overflow-y-auto"
            >
                <div className="flex items-center justify-center min-h-screen px-4">
                    <div className="relative bg-white w-full max-w-lg mx-auto rounded-lg shadow-lg">
                        <div className="flex justify-between items-center border-b p-4">
                            <h3 className="text-lg font-medium">Files Preview</h3>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="text-gray-600 hover:text-gray-800"
                            >
                                Close
                            </button>
                        </div>
                        <div className="p-4">
                            <div className="flex justify-between items-center mb-4">
                                <button onClick={handlePrevSlide} className="text-gray-600 hover:text-gray-800">
                                    <FaArrowLeft size={24} />
                                </button>
                                <div className="flex-grow text-center">
                                    <span className="font-semibold text-md">{currentFiles[currentSlideIndex]}</span>
                                </div>
                                <button onClick={handleNextSlide} className="text-gray-600 hover:text-gray-800">
                                    <FaArrowRight size={24} />
                                </button>
                            </div>
                            {/* Preview the file link */}
                            <div className="text-center">
                                <a
                                    href={`${config.apiUrl}/uploads/${currentFiles[currentSlideIndex]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 underline"
                                >
                                    Open File
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )}
    
        <div className="flex justify-center py-2">
            <button
                onClick={handleSendToPurchase}
                className="bg-teal-700 text-white font-semibold py-2 px-4 rounded-lg text-xs transition duration-200 hover:bg-teal-900"
            >
                Send to Purchase Team
            </button>
        </div>
    </div>
    
    );
};

export default AdminQuotationReview;
