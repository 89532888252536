import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import config from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuotationSubmission = () => {
  const [piDetails, setPiDetails] = useState([]);
  const [selectedPi, setSelectedPi] = useState(null);
  const [quotations, setQuotations] = useState([null, null, null]); 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Refs for file inputs
  const fileInputRefs = [useRef(null), useRef(null), useRef(null)];


    const fetchPiDetails = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/purchase/pidetails`);
        setPiDetails(response.data);
        console.log(response.data,'pidetails');
        
      } catch (err) {
        console.error('Error fetching PI details:', err);
      }
    };
    useEffect(() => {
    fetchPiDetails();
  }, []);

  const handleFileChange = (index, event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const newQuotations = [...quotations];
      newQuotations[index] = file;
      setQuotations(newQuotations);
    }
  };

  const handlePiSelect = (pi) => {
    setSelectedPi(pi);
    setQuotations([null, null, null]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const selectedQuotations = quotations.filter(Boolean);
  
    if (selectedQuotations.length < 1 || selectedQuotations.length > 3) {
      toast.error('Please upload at least 1 file and a maximum of 3 files.');
      return;
    }
  
    if (!selectedPi) {
      toast.error('Please select a Purchase Intent (PI) to submit quotations.');
      return;
    }
  
    const formData = new FormData();
    formData.append('piDetails', JSON.stringify({
      id:selectedPi.ID,
      RawMaterial: selectedPi.RawMaterial,
      Qty: selectedPi.Qty,
      TankName: selectedPi.TankName,
      RequestTime: selectedPi.RequestTime,
      PiNo: selectedPi.PiNo,
      Planthead: selectedPi.Planthead, 
    }));
  
    selectedQuotations.forEach((quotation, index) => {
      formData.append(`quotation`, quotation);
    });
  
    setIsLoading(true);
    try {
      const response = await axios.post(`${config.apiUrl}/purchasedep/submit-quotations`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Quotations submitted successfully:', response.data);
      toast.success('Quotations submitted successfully!');
      setQuotations([null, null, null]);
      setSelectedPi(null);
      setIsLoading(false);
      fetchPiDetails()
    } catch (error) {
      console.error('Error submitting quotations:', error);
      toast.error('Failed to submit quotations. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleRemoveFile = (index) => {
    const newQuotations = [...quotations];
    newQuotations[index] = null; 
    setQuotations(newQuotations);

    if (fileInputRefs[index].current) {
      fileInputRefs[index].current.value = '';
    }
  };
 
  
  return (
    <div className="container  bg-gray-900 min-h-screen p-6">
      <h1 className="text-center text-2xl font-bold text-white font-montserrat mb-6">
        Quotation Submission
      </h1>
  
      <div className="p-2">
        <h2 className="font-bold text-xl text-white mb-4">Purchase Intent Request</h2>
        <div className="overflow-x-auto">
          <table className="w-full border border-gray-700 rounded-lg text-sm text-center bg-gray-800">
            <thead className="bg-teal-600 text-gray-100">
              <tr>
                <th className="border border-gray-700 px-4 py-2">Select</th>
                <th className="border border-gray-700 px-4 py-2">Raw Material</th>
                <th className="border border-gray-700 px-4 py-2">Quantity</th>
                <th className="border border-gray-700 px-4 py-2">Tank Name</th>
                <th className="border border-gray-700 px-4 py-2">Request Time</th>
                <th className="border border-gray-700 px-4 py-2">Plant Head</th>
                <th className="border border-gray-700 px-4 py-2">PI.No</th>
              </tr>
            </thead>
            <tbody>
              {piDetails.length === 0 ? (
                <tr>
                  <td colSpan={7} className="text-white p-4">
                    Purchase Intent Request Not Found
                  </td>
                </tr>
              ) : (
                piDetails.map((pi) => (
                  <tr
                    key={pi.ID}
                    className={`text-white  ${
                      selectedPi?.ID === pi.ID ? '' : ''
                    } ${pi.is_Q_Ad ? '' : ''}`}
                  >
                    <td className="px-4 py-2">
                      <input
                        type="checkbox"
                        checked={selectedPi?.ID === pi.ID}
                        onChange={() => handlePiSelect(pi)}
                        disabled={pi.is_Q_Ad}
                      />
                    </td>
                    <td className=" px-4 py-2">{pi.RawMaterial}</td>
                    <td className=" px-4 py-2">{pi.Qty}</td>
                    <td className=" px-4 py-2">{pi.TankName}</td>
                    <td className=" px-4 py-2">
                      {new Date(pi.RequestTime).toLocaleString()}
                    </td>
                    <td className="px-4 py-2">{pi.Planthead}</td>
                    <td className=" px-4 py-2">{pi.PiNo}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
  
        {selectedPi && (
          <div className="mt-8 bg-gray-800 p-6 rounded-lg shadow-md">
            <h2 className="text-center text-lg font-bold text-gray-200 mb-4">
              Upload Quotations for {selectedPi.RawMaterial}
            </h2>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
              <div className="w-full max-w-md space-y-4">
                {Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <label className="text-[#438FD1] font-semibold text-sm whitespace-nowrap mr-4">
                      Upload File {index + 1}
                    </label>
                    <div className="flex items-center space-x-2 w-full">
                    <input
                      ref={fileInputRefs[index]}
                      type="file"
                      accept=".pdf,image/*"
                      onChange={(e) => handleFileChange(index, e)}
                      className="p-1 border border-gray-300 rounded text-sm text-white"
                     
                    />
                    {quotations[index] && (
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="ml-2 text-red-500 text-xs underline"
                      >
                        Remove
                      </button>
                    )}
                     </div>
                  </div>
                ))}
              </div>
  
              <button
                type="submit"
                className={`ml-4 w-32 px-3 py-1.5 text-xs  rounded-md shadow-md bg-teal-600 hover:bg-teal-900  ${
                  isLoading ? 'opacity-50  cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
              >
             {isLoading ? 'Submitting...' : 'Submit'}
              </button>
              {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 text-white">
                  Loading...
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  );
  
  
}  

export default QuotationSubmission;
