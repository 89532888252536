import React, { useState, useEffect } from 'react';
import ExpenseForm from './ExpenseForm';
import RevenueForm from './RevenueForm';
import axios from 'axios';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../Logsheet/LoadingSpinner';

const FinanceForm = () => {
    const [isRevenuePage, setIsRevenuePage] = useState(true);
    const [formData, setFormData] = useState({
        purchaseOrderNo: '',
        invoiceNo: '',
        ewayBillNo: '',
        date: '',
        vehicleNo: '',
        driverName: '',
        inandout: '',
        location: '',
        tankNo: '',
        fuelName: '',
        grossWeight: 0,
        tareWeight: 0,
        netWeight: 0,
        uom: '',
        color: '',
        density: 0, // Set default to 0 or any other appropriate value
        remarks: '',
        barrelCount: 0,
        litresPerBarrel: 0,
        amount: '',
        gstNo: ''
    });
    
    const [invoiceOptions, setInvoiceOptions] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state

    const fetchInvoiceOptions = async () => {
        try {
            const endpoint = isRevenuePage ? 'outward' : 'inward';
            const response = await axios.get(`${config.apiUrl}/in_out/${endpoint}`);
            setInvoiceOptions(response.data.map(item => ({
                value: item.invoiceNo,
                label: item.invoiceNo
            })));
        } catch (error) {
            console.error('Error fetching invoice options:', error);
        }
    };

    useEffect(() => {
        fetchInvoiceOptions();
    }, [isRevenuePage]);

    const handleInvoiceSelect = async (selectedInvoice) => {
        if (selectedInvoice) {
            try {
                const endpoint = isRevenuePage ? 'outward' : 'inward';
                const response = await axios.get(`${config.apiUrl}/in_out/${endpoint}/${selectedInvoice.value}`);
                const data = response.data;
                console.log(data);
                

                setFormData({
                    purchaseOrderNo: data.purchaseOrderNo,
                    invoiceNo: selectedInvoice ? selectedInvoice.value : '', 
                    ewayBillNo: data.ewayBillNo,
                    date: new Date(data.date).toISOString().split('T')[0], 
                    vehicleNo: data.vehicle_no,
                    driverName: data.driver_name,
                    inandout: data.outward_to_and_details || data.inward_from_and_details,
                    location: data.location,
                    tankNo: data.tank_no,
                    fuelName: data.fuel_name,
                    grossWeight: data.gross_weight || 0,
                    tareWeight: data.tare_weight,
                    netWeight: data.net_weight,
                    uom: data.uom,
                    color: data.color,
                    density: data.density,
                    remarks: data.remarks,
                    barrelCount: data.barrelCount || 0,
                    litresPerBarrel: data.litresPerBarrel || 0,
                    amount: '',
                    gstNo: '',
                });
            } catch (error) {
                console.error('Error fetching invoice details:', error);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleToggleChange = () => {
        setIsRevenuePage(prev => !prev);
        setFormData({}); // Reset form data when toggling between forms
    };

    const handleCancel = () => {
        setFormData({
            purchaseOrderNo: '',
            invoiceNo: '',
            ewayBillNo: '',
            date: '',
            vehicleNo: '',
            driverName: '',
            inandout: '',
            location: '',
            tankNo: '',
            fuelName: '',
            grossWeight: 0,
            tareWeight: 0,
            netWeight: 0,
            uom: '',
            color: '',
            density: 0,
            remarks: '',
            barrelCount: 0,
            litresPerBarrel: 0,
            amount: '',
            gstNo: ''
        });
    };

    const validateForm = () => {
        // Required fields excluding weights (grossWeight, tareWeight, netWeight)
        const requiredFields = [
            'invoiceNo', 'purchaseOrderNo', 'ewayBillNo', 'date', 
            'vehicleNo', 'driverName', 'inandout', 'location',
            'tankNo', 'fuelName', 'uom', 'color', 'density', 
            'remarks', 'amount', 'gstNo'
        ];
    
        // Check for other required fields
        for (const field of requiredFields) {
            if (formData[field] === undefined || formData[field] === '') {
                toast.error(`Field ${field} is missing or empty: ${formData[field]}`);
                return false;
            }
        }
    
        // Validate barrelCount, litresPerBarrel, and density (allow 0 or 0.00 as valid values)
        if (
            formData.barrelCount === undefined || 
            formData.litresPerBarrel === undefined ||
            formData.density === undefined
        ) {
            toast.error('Barrel Count, Litres per Barrel, and Density must not be undefined.');
            return false;
        }
    
        // Ensure that barrelCount, litresPerBarrel, and density can be 0 or 0.00
        if (isNaN(formData.barrelCount) || isNaN(formData.litresPerBarrel) || isNaN(formData.density)) {
            toast.error('Barrel Count, Litres per Barrel, and Density must be valid numbers.');
            return false;
        }
    
        return true;
    };
    
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        
        if (!validateForm()) {
            return;
        }
        setLoading(true); // Start loading
        try {
            const endpoint = isRevenuePage ? 'revenue' : 'expense';
            const response = await axios.post(`${config.apiUrl}/expenses/${endpoint}`, formData);
            toast.success('Form submitted successfully!');
           // Reset form data to initial state
        setFormData({
            purchaseOrderNo: '',
            invoiceNo: '',
            ewayBillNo: '',
            date: '',
            vehicleNo: '',
            driverName: '',
            inandout: '',
            location: '',
            tankNo: '',
            fuelName: '',
            grossWeight: 0,
            tareWeight: 0,
            netWeight: 0,
            uom: '',
            color: '',
            density: 0,
            remarks: '',
            barrelCount: 0,
            litresPerBarrel: 0,
            amount: '',
            gstNo: ''
        });
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Form submission failed.');
        }
        finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
        <div className="bg-gray-900 min-h-screen p-6">
        <div className="p-4 bg-gray-800  text-white shadow-lg rounded-lg ">
        <div className="flex items-center mb-4">
                    {/* Checkbox aligned to the left */}
                    <input
                        type="checkbox"
                        checked={isRevenuePage}
                        onChange={handleToggleChange}
                        className="form-checkbox h-5 w-5 text-blue-600 mr-3" // Add margin to right for spacing
                    />
                    {/* Centering the heading */}
                    <span className="text-2xl font-semibold font-montserrat flex-grow text-center">
                        {isRevenuePage ? 'Revenue' : 'Expense'}
                    </span>
                </div>

            {isRevenuePage ? (
                <RevenueForm
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleInvoiceSelect={handleInvoiceSelect}
                    invoiceOptions={invoiceOptions}
                />
            ) : (
                <ExpenseForm
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleInvoiceSelect={handleInvoiceSelect}
                    invoiceOptions={invoiceOptions}
                />
            )}

            <div className="flex justify-center space-x-4 mt-6">
                <button
                    type="submit"
                    onClick={handleFormSubmit}
                   className="w-32 px-3 py-1.5 text-xs text-white bg-blue-600 hover:bg-blue-700 rounded-md shadow-md"
                >
                    Submit
                </button>
                <button
                    type="button"
                    onClick={handleCancel}
                     className="ml-4 w-32 px-3 py-1.5 text-xs text-white bg-red-600 hover:bg-red-700 rounded-md shadow-md "
                >
                    Cancel
                </button>
            </div>
            {loading && <LoadingSpinner />}
        </div>
        </div>
        </>
    );
};

export default FinanceForm;
