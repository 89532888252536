import React from 'react';
import Select from 'react-select';

const RevenueForm = ({ formData, handleInputChange, handleInvoiceSelect, invoiceOptions }) => {
    return (
<div className="space-y-2 text-gray-900">
    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="invoiceNo">Invoice No<span className="text-red-500"> *</span></label>
            <Select
    name="invoiceNo"
    value={invoiceOptions.find(option => option.value === formData.invoiceNo) || null}
    onChange={handleInvoiceSelect}
    options={invoiceOptions}
    className="text-sm mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
    styles={{
        control: (provided) => ({
            ...provided,
            height: '32px', // Adjust this value as needed
            minHeight: '32px',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    }}
/>

        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="purchaseOrderNo">Purchase Order No</label>
            <input
                type="text"
                name="purchaseOrderNo"
                id="purchaseOrderNo"
                value={formData.purchaseOrderNo}
                onChange={handleInputChange}
                placeholder="Purchase Order No"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="ewayBillNo">Eway Bill No</label>
            <input
                type="text"
                name="ewayBillNo"
                id="ewayBillNo"
                value={formData.ewayBillNo}
                onChange={handleInputChange}
                placeholder="Eway Bill No"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
    </div>
    
    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="date">Date</label>
            <input
                type="text"
                name="date"
                id="date"
                value={formData.date}
                onChange={handleInputChange}
                placeholder="Date"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="vehicleNo">Vehicle No</label>
            <input
                type="text"
                name="vehicleNo"
                id="vehicleNo"
                value={formData.vehicleNo}
                onChange={handleInputChange}
                placeholder="Vehicle No"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="driverName">Driver Name</label>
            <input
                type="text"
                name="driverName"
                id="driverName"
                value={formData.driverName}
                onChange={handleInputChange}
                placeholder="Driver Name"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
    </div>

    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="inandout">In/Out</label>
            <input
                type="text"
                name="inandout"
                id="inandout"
                value={formData.inandout}
                onChange={handleInputChange}
                placeholder="In/Out"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="location">Location</label>
            <input
                type="text"
                name="location"
                id="location"
                value={formData.location}
                onChange={handleInputChange}
                placeholder="Location"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="tankNo">Tank No</label>
            <input
                type="text"
                name="tankNo"
                id="tankNo"
                value={formData.tankNo}
                onChange={handleInputChange}
                placeholder="Tank No"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
    </div>

    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="fuelName">Fuel Name</label>
            <input
                type="text"
                name="fuelName"
                id="fuelName"
                value={formData.fuelName}
                onChange={handleInputChange}
                placeholder="Fuel Name"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="grossWeight">Gross Weight</label>
            <input
                type="number"
                name="grossWeight"
                id="grossWeight"
                value={formData.grossWeight || 0}
                onChange={handleInputChange}
                placeholder="Gross Weight"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="tareWeight">Tare Weight</label>
            <input
                type="number"
                name="tareWeight"
                id="tareWeight"
                value={formData.tareWeight || 0}
                onChange={handleInputChange}
                placeholder="Tare Weight"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
    </div>

    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="netWeight">Net Weight</label>
            <input
                type="number"
                name="netWeight"
                id="netWeight"
                value={formData.netWeight || 0}
                onChange={handleInputChange}
                placeholder="Net Weight"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="uom">UOM</label>
            <input
                type="text"
                name="uom"
                id="uom"
                value={formData.uom}
                onChange={handleInputChange}
                placeholder="UOM"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="color">Color</label>
            <input
                type="text"
                name="color"
                id="color"
                value={formData.color}
                onChange={handleInputChange}
                placeholder="Color"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
    </div>

    <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
            <label className="block text-sm font-medium text-white" htmlFor="density">Density</label>
            <input
                type="text"
                name="density"
                id="density"
                value={formData.density}
                onChange={handleInputChange}
                placeholder="Density"
                className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                readOnly
            />
        </div>
                  <div>
                  <label className="block text-sm font-medium text-white" htmlFor="remarks">Remarks</label>
                <input
                    type="text"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleInputChange}
                    placeholder="Remarks"
                    className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                    readOnly
                />
                </div>
                  <div>
                  <label className="block text-sm font-medium text-white" htmlFor="barrelCount">Barrel Count</label>
                <input
                    type="number"
                    name="barrelCount"
                    value={formData.barrelCount || 0}
                    onChange={handleInputChange}
                    placeholder="Barrel Count"
                    className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                    readOnly
                />
                </div>
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                <div>
                <label className="block text-sm font-medium text-white" htmlFor="litresPerBarrel">Litres per Barrel</label>
                <input
                    type="number"
                    name="litresPerBarrel"
                    value={formData.litresPerBarrel || 0}
                    onChange={handleInputChange}
                    placeholder="Litres Per Barrel"
                    className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                    readOnly
                />
                </div>
                <div>
                <label className="block text-sm font-medium text-white" htmlFor="amount">Amount<span className="text-red-500"> *</span></label>
                <input
                    type="text"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                />
                </div>
                <div>
                <label className="block text-sm font-medium text-white" htmlFor="gstNo">GST No<span className="text-red-500"> *</span></label>
                <input
                    type="text"
                    name="gstNo"
                    value={formData.gstNo}
                    onChange={handleInputChange}
                    placeholder="GST No"
                    className="text-sm mt-1 p-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
                />
                </div>
            </div>
        </div>
    );
};

export default RevenueForm;
