import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaDownload } from 'react-icons/fa';
import config from '../config';
import DownloadIO from './Download';

const InoutHistory = ({ closeTable }) => {
  const [inwardData, setInwardData] = useState([]);
  const [outwardData, setOutwardData] = useState([]);
  const [showInward, setShowInward] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchInitialData();
  }, [showInward]);

  const fetchInitialData = async () => {
    if (showInward) {
      fetchInwardData();
    } else {
      fetchOutwardData();
    }
  };

  const fetchInwardData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.apiUrl}/in_out/inwarddetails`);
      setInwardData(response.data);
     
      setFilteredData(response.data);
      console.log(filteredData); 
    } catch (error) {
      setError('Failed to fetch inward data');
    } finally {
      setLoading(false);
    }
  };

  const fetchOutwardData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.apiUrl}/in_out/outwarddetails`);
      setOutwardData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      setError('Failed to fetch outward data');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    const dataToFilter = showInward ? inwardData : outwardData;
    const filtered = dataToFilter.filter(item =>
      Object.values(item).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase()) // Ensure value is not null or undefined
      )
    );
    setFilteredData(applyDateFilter(filtered));
  };

  const applyDateFilter = (data) => {
    if (!startDate || !endDate) return data; // If no date range is selected, return all data
    const start = new Date(startDate);
    const end = new Date(endDate);
    return data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= start && itemDate <= end;
    });
  };

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredData(applyDateFilter(showInward ? inwardData : outwardData));
    }
  }, [searchTerm, startDate, endDate]);

  const handleClearSearch = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    setFilteredData(showInward ? inwardData : outwardData);
  };

  const handleInwardChange = () => {
    setShowInward(true);
  };

  const handleOutwardChange = () => {
    setShowInward(false);
  };

  const opendown = () => setIsDownloadOpen(true);
  const closedown = () => setIsDownloadOpen(false);

  return (
    <>
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-2xl shadow-md max-w-full w-full h-full flex flex-col relative">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold text-white">
            {showInward ? 'Inward History' : 'Outward History'}
          </h2>
          <div className="flex items-center">
            <button
              onClick={opendown}
              className="text-yellow-600 text-xs px-2 py-1.5 bg-white rounded-lg transition flex items-center"
            >
              <FaDownload size={14} />
            </button>
            <button onClick={closeTable} className="ml-8 text-red-500">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-600 bg-transparent p-1 w-40 text-white rounded-lg"
            />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border border-gray-600 bg-transparent p-1 text-white rounded-lg"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border border-gray-600 bg-transparent p-1 text-white rounded-lg"
            />
            <button
              onClick={handleSearch}
              className="bg-blue-600 text-white px-3 py-1 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <button
              onClick={handleClearSearch}
              className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition-colors"
            >
              Clear
            </button>
          </div>

          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={showInward}
                onChange={handleInwardChange}
                className="form-checkbox"
              />
              <span className="ml-2">Show Inward</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={!showInward}
                onChange={handleOutwardChange}
                className="form-checkbox"
              />
              <span className="ml-2">Show Outward</span>
            </label>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="text-center text-white">Loading...</div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div>
          ) : filteredData.length > 0 ? (
            <table className="table-auto w-full bg-gray-700 border border-gray-500 max-h-[calc(100vh-188px)] overflow-y-auto">
              <thead className="bg-teal-600 text-white sticky top-0 text-xs text-center text-nowrap">
                <tr>
                  <th className="px-2 py-2">Invoice No</th>
                  <th className="px-2 py-2">Purchase Order No</th>
                  <th className="px-2 py-2">Eway Bill No</th>
                  <th className="px-2 py-2">Date</th>
                  <th className="px-2 py-2">Vehicle No</th>
                  <th className="px-2 py-2">Driver Name</th>
                  <th className="px-2 py-2">{showInward ? 'Inward From Company' : 'Outward to Company'}</th>
                    <th className="px-2 py-2 ">Location</th>
                    <th className="px-2 py-2 ">Tank No</th>
                    <th className="px-2 py-2">Fuel Name</th>
                    <th className="px-2 py-2 ">HSN Code</th>
                    <th className="px-2 py-2 ">Gross Weight</th>
                    <th className="px-2 py-2 ">Tare Weight</th>
                    <th className="px-2 py-2">Net Weight</th>
                    <th className="px-2 py-2">UOM</th>
                    <th className="px-2 py-2 ">Color</th>
                    <th className="px-2 py-2">Density</th>
                    <th className="px-2 py-2 ">Remarks</th>
                    <th className="px-2 py-2 ">Manifest No</th>
                    <th className="px-2 py-2 ">Schedule</th>
                    <th className="px-2 py-2 ">Pollution Board</th>
                    <th className="px-2 py-2 ">Barrel Count</th>
                    <th className="px-2 py-2 ">Litres Per Barrel</th>
                    <th className="px-2 py-2 ">Created At</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index} className="text-xs text-center border-b text-nowrap">
                     <td className="px-2 py-2 ">{item.invoiceNo}</td>
                       <td className="px-2 py-2">{item.purchaseOrderNo}</td>
                       <td className="px-2 py-2">{item.ewayBillNo}</td>
                       <td className="px-2 py-2">{new Date(item.date).toLocaleDateString()}</td>
                       <td className="px-2 py-2 ">{item.vehicle_no}</td>
                       <td className="px-2 py-2">{item.driver_name}</td>
                       <td className="px-2 py-2">{showInward ? item.inward_from_and_details : item.outward_to_and_details}</td>
                       <td className="px-2 py-2">{item.location}</td>
                       <td className="px-2 py-2">{item.tank_no}</td>
                       <td className="px-2 py-2">{item.fuel_name}</td>
                       <td className="px-2 py-2">{item.hsn_Code}</td>
                       <td className="px-2 py-2">{item.gross_weight.toFixed(2)}</td>
                       <td className="px-2 py-2">{item.tare_weight.toFixed(2)}</td>
                       <td className="px-2 py-2">{item.net_weight.toFixed(2)}</td>
                       <td className="px-2 py-2">{item.uom}</td>
                       <td className="px-2 py-2">{item.color}</td>
                       <td className="px-2 py-2">{item.density.toFixed(2)}</td>
                       <td className="px-2 py-2">{item.remarks}</td>
                       <td className="px-2 py-2">{item.manifest_No}</td>
                       <td className="px-2 py-2">{item.schedule}</td>
                       <td className="px-2 py-2">{item.pollution_board}</td>
                       <td className="px-2 py-2">{item.barrelCount}</td>
                       <td className="px-2 py-2">{item.litresPerBarrel.toFixed(2)}</td>
                       <td className="px-2 py-2">{new Date(item.created_at).toLocaleDateString()}</td>
                     </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center text-white">No data found</div>
          )}
        </div>
      </div>
    </div>
     {isDownloadOpen && (
        <DownloadIO
          inwardData={inwardData}
          outwardData={outwardData}
          showInward={showInward}
          closedown={closedown}
        />
      )}
      </>
  );
};

export default InoutHistory;
