import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const AddTankModal = ({ isOpen, onClose, fetchTanks }) => {
  const [formData, setFormData] = useState({
    tankname: '',
    tankradius: '',
    tankcapacity: '',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    
   
  if (!formData.tankname) {
    newErrors.tankname = 'Tank name is required.';
  } else if (!/[A-Z]/.test(formData.tankname) || !/[0-9]/.test(formData.tankname)) {
    newErrors.tankname = 'Tank name must contain at least one letter and one number.';
  }
    if (!formData.tankradius || formData.tankradius <= 0) newErrors.tankradius = 'Tank radius must be a positive number.';
    if (!formData.tankcapacity || formData.tankcapacity <= 0) newErrors.tankcapacity = 'Tank capacity must be a positive number.';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'tankname') {
      // Allow letters followed by numbers (e.g., A1)
      const cleanedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: cleanedValue,
      }));
    } else if (name === 'tankradius' || name === 'tankcapacity') {
      // Ensure positive number with optional decimal places
      const cleanedValue = value
        .replace(/[^0-9.]/g, '') // Remove non-numeric and non-decimal characters
        .replace(/(\..*)\..*/g, '$1') // Ensure only one decimal point
        .replace(/^0+([1-9])/, '$1') // Remove leading zeros except for 0.
        .replace(/(\.\d{2})\d+/g, '$1'); // Allow only up to two decimal places
      setFormData(prevData => ({
        ...prevData,
        [name]: cleanedValue,
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  

  const handleAddTank = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
        const response = await axios.post(`${config.apiUrl}/stock/addtanks`, formData);
        if (response.data.success) {
            toast.success('Tank added successfully!');
            fetchTanks(); 
            onClose(); 
        } else {
            // Show the specific error message from the backend
            toast.error(response.data.message);
        }
    } catch (error) {
        // Check if the error response exists and contains a message
        if (error.response && error.response.data && error.response.data.message) {
            // Display the error message returned from the backend
            toast.error(error.response.data.message);
        } else {
            // Fallback for any unexpected errors
            toast.error('An error occurred while adding the tank. Please try again.');
        }
    }
};


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="p-6 rounded-md w-full max-w-lg bg-white shadow-lg transform transition-all relative text-black">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b border-gray-300 pb-2">Add Storage Tank</h2>
        <button onClick={onClose} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <form onSubmit={handleAddTank}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-gray-700 mb-1 text-sm">
                <span className="text-red-500">*</span> Tank Name
              </label>
              <input
                type="text"
                name="tankname"
                value={formData.tankname}
                onChange={handleFormChange}
                className={`form-input w-full border ${errors.tankname ? 'border-red-500' : 'border-gray-300'} rounded-md text-sm p-2`}
                placeholder="Enter tank name"
              />
              {errors.tankname && <p className="text-red-500 text-xs">{errors.tankname}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1 text-sm">
                <span className="text-red-500">*</span> Tank Radius
              </label>
              <input
                type="text"
                name="tankradius"
                value={formData.tankradius}
                onChange={handleFormChange}
                className={`form-input w-full border ${errors.tankradius ? 'border-red-500' : 'border-gray-300'} rounded-md text-sm p-2`}
                placeholder="Enter tank radius"
              />
              {errors.tankradius && <p className="text-red-500 text-xs">{errors.tankradius}</p>}
            </div>

            <div>
              <label className="block text-gray-700 mb-1 text-sm">
                <span className="text-red-500">*</span> Tank Capacity (KL)
              </label>
              <input
                type="text"
                name="tankcapacity"
                value={formData.tankcapacity}
                onChange={handleFormChange}
                className={`form-input w-full border ${errors.tankcapacity ? 'border-red-500' : 'border-gray-300'} rounded-md text-sm p-2`}
                placeholder="Enter tank capacity"
              />
              {errors.tankcapacity && <p className="text-red-500 text-xs">{errors.tankcapacity}</p>}
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="text-sm px-3 py-1.5 bg-rose-500 hover:bg-rose-600 text-white rounded-md  transition"
            >
              Add Tank
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTankModal;
