import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faChevronDown,
  faChevronRight,
  faTachometerAlt,
  faCubes,
  faClipboardList,
  faExchangeAlt,
  faMoneyBillWave,
  faFileAlt,
  faFileInvoice,
  faUserCheck,
  faDollarSign,
  faCoins,
  faWallet,
  faMoneyCheck,
  faCalculator

} from '@fortawesome/free-solid-svg-icons';
import LogoImage from '../assets/ksr.jpg';

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user?.user?.user_role;

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Small Sidebar */}
      <div className="w-20 bg-gray-800 text-white shadow-lg">
        <div className="flex flex-col justify-between h-full">
          {/* Logo Section */}
          <div className="mb-8">
            <img src={LogoImage} alt="Logo" className="h-10 w-10 mt-4 mx-auto rounded-full" />
          </div>

          {/* Links Section */}
          <ul className="flex-grow">
            <SidebarLink to="dashboard" icon={faTachometerAlt} color="text-blue-400" />
            <SidebarLink to="stock" icon={faCubes} color="text-green-400" />
            <SidebarLink to="logsheet" icon={faClipboardList} color="text-yellow-400" />
            <SidebarLink to="in_out" icon={faExchangeAlt} color="text-purple-400" />
            {userRole === 'Admin' && (
              <SidebarLink to="expenses" icon={faMoneyBillWave} color="text-rose-400" />
            )}
            <SidebarLink to="PI" icon={faFileAlt} color="text-orange-400" />
            <SidebarSubMenu />
            <SidebarLink to="PlantHead" icon={faUserCheck} color="text-teal-400" />
            <SidebarLink to="fin_dep" icon={faCoins} color="text-red-400" />
          </ul>

          {/* Logout Section */}
          <div className="p-4 relative group">
            <button
              className="flex items-center justify-center w-full text-gray-400 hover:text-rose-600 transition"
              onClick={handleLogout}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="text-md" />
            </button>
            {/* Tooltip */}
            <span className="absolute left-16 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg z-50">
              Logout
            </span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

const SidebarLink = ({ to, icon, color }) => (
  <li className="relative group">
    <Link
      to={to}
      className="flex items-center justify-center w-full p-4 hover:bg-gray-700 rounded transition"
    >
      <FontAwesomeIcon icon={icon} className={`text-md ${color}`} />
      {/* Tooltip */}
      <span className="absolute left-16 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg z-50">
        {to.charAt(0).toUpperCase() + to.slice(1)}
      </span>
    </Link>
  </li>
);

const SidebarSubMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubMenu = () => setIsOpen((prev) => !prev);

  return (
    <li className="group relative">
      <div
        className="flex items-center justify-center w-full p-4 hover:bg-gray-700 rounded cursor-pointer"
        onClick={toggleSubMenu}
      >
        <FontAwesomeIcon icon={faFileInvoice} className="text-md text-cyan-400 ml-2" />
        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faChevronRight}
          className="ml-1 text-xs text-gray-400 transition-transform"
        />
      </div>

      {/* Submenu */}
      <ul
        className={`${
          isOpen ? 'opacity-100 max-h-40' : 'opacity-0 max-h-0'
        } transition-all duration-300 ease-in-out overflow-hidden bg-gray-800 text-white text-xs px-1 py-1 rounded shadow-lg z-50`}
      >
        <SidebarSubLink to="quotation" label="PI Details" />
        <SidebarSubLink to="po_form" label="PO Form" />
        <SidebarSubLink to="po_details" label="PO Details" />
      </ul>
    </li>
  );
};

const SidebarSubLink = ({ to, label }) => (
  <li className="py-0.5">
    <Link
      to={to}
      className="block text-xs px-2 py-1 hover:text-rose-600 transition whitespace-nowrap"
    >
      {label}
    </Link>
  </li>
);

export default Sidebar;
