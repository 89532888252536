import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';

const BarreltoTank = ({ barrelId, material,qty, onTransferComplete, onClose ,fetchBarrelStock,yard}) => {
    const [transferQty, setTransferQty] = useState('');
    const [targetTank, setTargetTank] = useState('');
    const [tankOptions, setTankOptions] = useState([]);

    // Fetch tank options on component mount
    useEffect(() => {
        const fetchTankOptions = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/stock/getTanks`);
                if (response.data && Array.isArray(response.data.data)) {
                    setTankOptions(response.data.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching tank options:', error);
            }
        };

        fetchTankOptions();
    }, []);


    
    const handleTransfer = async () => {
        // Validate inputs
        if (!targetTank || !transferQty) {
            toast.error('Please fill all fields before transferring.');
            return;
        }

        try {
            // Send request to transfer stock
            const response = await axios.post(`${config.apiUrl}/stock/bl-tk-transfer`, {
                barrelId,
                material, 
                yard,// Include material name in the request
                transferQty: parseFloat(transferQty),
                targetTankName: targetTank,
            });

            toast.success(response.data.message);
            onTransferComplete(); // Notify parent component of success
           
            onClose(); // Close modal on success
        } catch (error) {
            toast.error(error.response?.data?.message);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-gray-800 p-6 rounded-xl shadow-xl w-96 relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-red-500 hover:text-red-600"
                >
                    ✖
                </button>
                <h3 className="text-lg font-semibold text-center text-white mb-4">
                    Transfer Stock
                </h3>

                <div className="space-y-4">
                    <div>
                        <label className="block text-sm text-white mb-1">Material</label>
                        <input
                            type="text"
                            value={material}
                            readOnly
                            className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none"
                        />
                    </div>
                    <div>
                        <label className="block text-sm text-white mb-1">Target Tank Name</label>
                        <select
                            value={targetTank}
                            onChange={(e) => setTargetTank(e.target.value)}
                            className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">Select Tank</option>
                            {tankOptions.map((tank) => (
                                <option key={tank.tankname} value={tank.tankname}>
                                    {tank.tankname}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm text-white mb-1">Transfer Quantity (KL)</label>
                        <input
                            type="number"
                            placeholder="Enter Transfer Quantity"
                            value={transferQty}
                            onChange={(e) => setTransferQty(e.target.value)}
                            className="w-full p-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                         <p className="text-xs text-gray-400 mt-1">
                            Equivalent in KL: {(parseFloat(transferQty) / 1000).toFixed(2)} KL
                        </p>
                    </div>
                    <button
                        onClick={handleTransfer}
                        className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition-all"
                    >
                        Transfer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BarreltoTank;
