import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DownloadIO = ({ inwardData, outwardData, showInward, closedown }) => {
  const [format, setFormat] = useState('');
  console.log(outwardData,'outward');
  
  const convertTime = (timestamp) => {
    return new Date(timestamp).toISOString().slice(0, 19).replace('T', ' ');
  };
  // Utility function to get current date in dd-mm-yyyy format
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const downloadPDF = () => {
    const doc = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
    const pageWidth = doc.internal.pageSize.width;
    const margin = 10;
    let currentPage = 1;
    const Data = showInward ? inwardData : outwardData;
    if (Data.length === 0) {
      doc.text('No data available', margin, margin + 10);
      doc.save(`${showInward ? 'inward' : 'outward'}_${getCurrentDate()}.pdf`);
      return;
    }
    // Define headers and table data for each part
    const headersPart1 = ['Invoice No', 'Purchase Order No', 'Eway Bill No', 'Date', 'Vehicle No', 'Driver Name', 'Details', 'Location', 'Tank No', 'Fuel Name', 'HSN Code'];
    const headersPart2 = ['Gross Weight', 'Tare Weight', 'Net Weight', 'UOM', 'Color', 'Density', 'Remarks','ManifestNo','schedule','pollution Board', 'Barrel Count', 'Litres Per Barrel', 'Created At'];
    const tableBodyPart1 = Data.map(item => [
      item.invoiceNo,
      item.purchaseOrderNo,
      item.ewayBillNo,
      new Date(item.date).toLocaleDateString(),
      item.vehicle_no,
      item.driver_name,
      showInward ? item.inward_from_and_details : item.outward_to_and_details,
      item.location,
      item.tank_no,
      item.fuel_name,
      item.hsn_Code
    ]);
  
    const tableBodyPart2 = Data.map(item => [
      item.gross_weight.toFixed(2),
      item.tare_weight.toFixed(2),
      item.net_weight.toFixed(2),
      item.uom,
      item.color,
      item.density.toFixed(2),
      item.remarks,
      item.manifest_No,
      item.schedule,
      item.pollution_board,
      item.barrelCount.toFixed(2),
      item.litresPerBarrel.toFixed(2),
      new Date(item.created_at).toLocaleDateString()
    ]);
  
    const columnStylesPart1 = {
      0: { halign: 'center' },  // Invoice No
      1: { halign: 'center' },  // Purchase Order No
      2: { halign: 'center' },  // Eway Bill No
      3: { halign: 'center' },  // Date
      4: { halign: 'center' },  // Vehicle No
      5: { halign: 'center' },  // Driver Name
      6: { halign: 'center' },  // Details
      7: { halign: 'center' },  // Location
      8: { halign: 'center' },  // Tank No
      9: { halign: 'center' } ,  // Fuel Name
      10: { halign: 'center' }   // hsn Code
    };
  
    const columnStylesPart2 = {
      0: { halign: 'center' },  // Gross Weight
      1: { halign: 'center' },  // Tare Weight
      2: { halign: 'center' },  // Net Weight
      3: { halign: 'center' },  // UOM
      4: { halign: 'center' },  // Color
      5: { halign: 'center' },  // Density
      6: { halign: 'center' },  // Remarks
      7: { halign: 'center' },   // manifest_No
      8: { halign: 'center' },  // schedule
      9: { halign: 'center' },  // pollution board
      10: { halign: 'center' },  // Barrel Count
      11: { halign: 'center' },  // Litres Per Barrel
      12: { halign: 'center' }   // Created At
    };
  
    // Define table options for both parts
    const commonTableOptions = {
      theme: 'grid',
      styles: {
        fontSize: 8,
        cellPadding: 2,
        lineColor: [128, 128, 128], // Border color to grey
        lineWidth: 0.1,             // Border width
        
      },
      headStyles: {
        fillColor: [0, 51, 102],
        textColor: [255, 255, 255],
        halign: 'center',
      },
      margin: { left: margin, right: margin },
      pageBreak: 'auto',
      didDrawPage: (data) => {
        doc.setFontSize(10);
        doc.text(`Page ${currentPage}`, pageWidth - margin - 10, doc.internal.pageSize.height - margin);
        currentPage++;
      }
    };
  
    // Add the heading on the first page
    doc.text(`${showInward ? 'Inward' : 'Outward'} Data`, margin, margin + 10);
  
    // Add the first part of the table
    doc.autoTable({
      ...commonTableOptions,
      startY: margin + 20 + 10, // Start Y adjusted to leave space for heading
      head: [headersPart1],
      body: tableBodyPart1,
      columnStyles: columnStylesPart1
    });
  
    // Add a new page
    doc.addPage();
    doc.text(`${showInward ? 'Inward' : 'Outward'} Data`, margin, margin + 10);
  
    // Add the second part of the table
    doc.autoTable({
      ...commonTableOptions,
      startY: margin + 20,
      head: [headersPart2],
      body: tableBodyPart2,
      columnStyles: columnStylesPart2
    });
  
    // Save the PDF
    doc.save(`${showInward ? 'inward' : 'outward'}_${getCurrentDate()}.pdf`);
  };
  

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Inward and Outward History');
  
    const tableData = showInward ? inwardData : outwardData;
  
    worksheet.columns = [
      { header: 'Invoice No', key: 'invoiceNo', width: 15 },
      { header: 'Purchase Order No', key: 'purchaseOrderNo', width: 15 },
      { header: 'Eway Bill No', key: 'ewayBillNo', width: 15 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Vehicle No', key: 'vehicle_no', width: 15 },
      { header: 'Driver Name', key: 'driver_name', width: 15 },
      { header: showInward ? 'Inward From & Details' : 'Outward to & Details', key: 'details', width: 30 },
      { header: 'Location', key: 'location', width: 15 },
      { header: 'Tank No', key: 'tank_no', width: 10 },
      { header: 'Fuel Name', key: 'fuel_name', width: 15 },
      { header: 'HSN Code', key: 'hsn_Code', width: 15 },
      { header: 'Gross Weight', key: 'gross_weight', width: 15 },
      { header: 'Tare Weight', key: 'tare_weight', width: 15 },
      { header: 'Net Weight', key: 'net_weight', width: 15 },
      { header: 'UOM', key: 'uom', width: 10 },
      { header: 'Color', key: 'color', width: 10 },
      { header: 'Density', key: 'density', width: 15 },
      { header: 'Remarks', key: 'remarks', width: 15 },
      { header: 'Manifest No', key: 'manifest_No', width: 15 },
      { header: 'schedule', key: 'schedule', width: 15 },
      { header: 'pollution Board', key: 'pollution_board', width: 15 },
      { header: 'Barrel Count', key: 'barrelCount', width: 15 },
      { header: 'Litres Per Barrel', key: 'litresPerBarrel', width: 20 },
      { header: 'Created At', key: 'createdAt', width: 15 },
    ];
  
    tableData.forEach((item) => {
      worksheet.addRow({
        invoiceNo: item.invoiceNo,
        purchaseOrderNo: item.purchaseOrderNo,
        ewayBillNo: item.ewayBillNo,
        date: new Date(item.date).toLocaleDateString(),
        vehicle_no: item.vehicle_no,
        driver_name: item.driver_name,
        details: showInward ? item.inward_from_and_details : item.outward_to_and_details,
        location: item.location,
        tank_no: item.tank_no,
        fuel_name: item.fuel_name,
        hsn_Code: item.hsn_Code,
        gross_weight: item.gross_weight.toFixed(2),
        tare_weight: item.tare_weight.toFixed(2),
        net_weight: item.net_weight.toFixed(2),
        uom: item.uom,
        color: item.color,
        density: item.density.toFixed(2),
        remarks: item.remarks,
        manifest_No: item.manifest_No,
        schedule: item.schedule,
        pollution_board: item.pollution_board,
        barrelCount: item.barrelCount.toFixed(2),
        litresPerBarrel: item.litresPerBarrel.toFixed(2),
        createdAt: new Date(item.created_at).toLocaleDateString(),
      });
    });
  
    // Style header row
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { horizontal: 'center' };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '000080' }, // Navy Blue
    };
    worksheet.getRow(1).font.color = { argb: 'FFFFFF' }; // White text
  
    // Style all other rows
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.alignment = { horizontal: 'center' };
    });
  
    // Set column widths and center alignment for all cells
    worksheet.columns.forEach(column => {
      column.width = column.width || 20; // Adjust as needed
      column.eachCell({ includeEmpty: true }, cell => {
        cell.alignment = { horizontal: 'center' }; // Center alignment
      });
    });
  
    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buffer]));
    const link = document.createElement('a');
    link.href = url;
    link.download = `${showInward ? 'inward' : 'outward'}_${getCurrentDate()}.xlsx`;
    link.click();
  };
  

  const handleDownload = () => {
    if (format === 'pdf') {
      downloadPDF();
    } else if (format === 'excel') {
      downloadExcel();
    } else {
      toast.error('Please select a format to download.');
    }
    closedown();
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-gray-600 p-6 rounded-2xl shadow-lg max-w-sm w-full relative">
        <h2 className="text-xl  font-bold mb-4">Download Inward and Outward History</h2>
        <button onClick={closedown} className="absolute top-2 right-2">
          <FontAwesomeIcon className="text-red-500" icon={faTimes} />
        </button>
        <select
          value={format}
          onChange={(e) => setFormat(e.target.value)}
          className="w-full px-3 py-2 mb-4 text-gray-200 bg-gray-700 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 "
        >
          <option value="" disabled >Select Format</option>
          <option value="pdf">PDF</option>
          <option value="excel">Excel</option>
        </select>
        <div className="flex justify-end">
          <button
            onClick={handleDownload}
            className={`py-1.5 px-3 rounded-lg text-white text-sm
              ${!format ? 'bg-gray-900 cursor-not-allowed' : 'bg-rose-500 hover:bg-rose-600 text-white'}`}
            disabled={!format}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadIO;
