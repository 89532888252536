import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { PropagateLoader } from 'react-spinners';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [batchNoList, setBatchNoList] = useState([]);
  const [batchno, setBatchNo] = useState('');
  const [iframeLoading, setIframeLoading] = useState([true, true, true, true]); // For each iframe panel

  const fetchBatchNo = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/logsheet/fetchbatchNoList`);
      if (response.data && Array.isArray(response.data)) {
        setBatchNoList(response.data);
        if (response.data.length > 0) {
          setBatchNo(response.data[response.data.length - 1].batchNo);
        }
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching batchNoList:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchBatchNo();
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleIframeLoad = (index) => {
    setIframeLoading((prev) => {
      const newLoading = [...prev];
      newLoading[index] = false;
      return newLoading;
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-900">
        <PropagateLoader color="#50cfc0" size={15} />
      </div>
    );
  }

  return (
    <div className="bg-gray-900 min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <style>
          {`
            @keyframes marquee {
              0% { transform: translateX(100%); }
              100% { transform: translateX(-100%); }
            }

            .marquee {
              display: inline-block;
              white-space: nowrap;
              box-sizing: border-box;
              animation: marquee 30s linear infinite;
              color: #ffffff; /* Text color */
              padding: 8px;
              margin-bottom: 20px;
            }
          `}
        </style>
        
        <div className="flex justify-center overflow-hidden">
        <div className="marquee">
          ⚡ Welcome to the Dashboard! Stay updated with live batch information and key metrics. | 
          Monitor all batch activities seamlessly in real-time. | Batch No: {batchno} | 
          Make informed decisions with our interactive insights! 🚀
        </div>
      </div>
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-white">Dashboard</h1>
      
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mt-8">
          {[1].map((panelId, index) => (
            <div
              key={panelId}
              className="bg-gray-800 shadow-lg rounded-xl overflow-hidden relative"
            >
              {iframeLoading[index + 5] && ( 
                <div className="absolute inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50">
                  <PropagateLoader color="#50cfc0" size={10} />
                </div>
              )}
              <iframe
                src={`https://ksrinsights.fiveytech.com/d-solo/de0xmdn08wcn4e/ksr-live?orgId=1&panelId=${panelId}`}
                width="100%"
                height="350"
                frameBorder="0"
                onLoad={() => handleIframeLoad(index + 5)} 
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {[3,2].map((panelId, index) => (
            <div
              key={panelId}
              className="bg-gray-800 shadow-lg rounded-xl overflow-hidden relative"
            >
              {iframeLoading[index + 5] && ( 
                <div className="absolute inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50">
                  <PropagateLoader color="#50cfc0" size={10} />
                </div>
              )}
              <iframe
                src={`https://ksrinsights.fiveytech.com/d-solo/de0xmdn08wcn4e/ksr-live?orgId=1&panelId=${panelId}`}
                width="100%"
                height="350"
                frameBorder="0"
                onLoad={() => handleIframeLoad(index + 5)} 
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {[11,4].map((panelId, index) => (
            <div
              key={panelId}
              className="bg-gray-800 shadow-lg rounded-xl overflow-hidden relative"
            >
              {iframeLoading[index + 5] && ( 
                <div className="absolute inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50">
                  <PropagateLoader color="#50cfc0" size={10} />
                </div>
              )}
              <iframe
                src={`https://ksrinsights.fiveytech.com/d-solo/de0xmdn08wcn4e/ksr-live?orgId=1&panelId=${panelId}`}
                width="100%"
                height="450"
                frameBorder="0"
                onLoad={() => handleIframeLoad(index + 5)} // Adjust the index for footer panels
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
        {[12].map((panelId, index) => (
           <div
           key={panelId}
           className="bg-gray-800 shadow-lg rounded-xl overflow-hidden relative"
         >
           {iframeLoading[index + 5] && ( 
                <div className="absolute inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50">
                  <PropagateLoader color="#50cfc0" size={10} />
                </div>
              )}
           <iframe 
           src={`https://ksrinsights.fiveytech.com/d-solo/de0xmdn08wcn4e/ksr-live?orgId=1&panelId=${panelId}`} 
        width="100%" 
        height="450" 
        frameborder="0"
        onLoad={() => handleIframeLoad(index + 5)}
        />
           
           </div>
          ))}
        </div>
       
{/* Footer Panels Section */}
<div className="flex items-center space-x-4 mt-5">
            <label className="text-sm font-medium text-gray-300">Batch No:</label>
            <select
              className="p-2 text-sm bg-gray-800 text-white border border-teal-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 transition-all duration-200"
              onChange={(e) => setBatchNo(e.target.value)}
              value={batchno}
            >
              <option value="">Select Batch Number</option>
              {batchNoList.map((batch, index) => (
                <option key={index} value={batch.batchNo}>
                  {batch.batchNo}
                </option>
              ))}
            </select>
          </div>
        {/* Panel Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-5">
          {[5, 8, 6, 7, 10, 9].map((panelId, index) => (
            <div
              key={panelId}
              className="bg-gray-800 shadow-lg rounded-xl overflow-hidden relative"
            >
              {iframeLoading[index] && (
                <div className="absolute inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50">
                  <PropagateLoader color="#50cfc0" size={10} />
                </div>
              )}
              <iframe
                src={`https://ksrinsights.fiveytech.com/d-solo/de0xmdn08wcn4e/ksr-live?orgId=1&var-batchno=${batchno}&panelId=${panelId}`}
                width="100%"
                height="150"
                frameBorder="0"
                onLoad={() => handleIframeLoad(index)} // Pass the current index
              />
            </div>
          ))}
        </div>

        
      </div>
    </div>
  );
};

export default Dashboard;
