import React from 'react';
import Select from 'react-select';

const ExpenseForm = ({ formData, handleInputChange, handleInvoiceSelect, invoiceOptions }) => {
    return (
        <div className="space-y-2 text-gray-900">
           <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="invoiceNo" className="block text-sm font-medium text-white">Invoice No <span className="text-red-500"> *</span></label>
        <Select
            id="invoiceNo"
            name="invoiceNo"
            value={invoiceOptions.find(option => option.value === formData.invoiceNo) || null}
            onChange={handleInvoiceSelect}
            options={invoiceOptions}
            className="text-sm mt-1 block w-full border text-black border-gray-300 rounded-md shadow-sm"
            styles={{
                control: (provided) => ({
                    ...provided,
                    height: '32px', // Adjust this value as needed
                    minHeight: '32px',
                }),
                menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                }),
            }}
        />
    </div>
    <div>
        <label htmlFor="purchaseOrderNo" className="block text-sm font-medium text-white">Purchase Order No</label>
        <input
            type="text"
            id="purchaseOrderNo"
            name="purchaseOrderNo"
            value={formData.purchaseOrderNo}
            onChange={handleInputChange}
            placeholder="Purchase Order No"
            className=" text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="ewayBillNo" className="block text-sm font-medium text-white">Eway Bill No</label>
        <input
            type="text"
            id="ewayBillNo"
            name="ewayBillNo"
            value={formData.ewayBillNo}
            onChange={handleInputChange}
            placeholder="Eway Bill No"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
</div>

<div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="date" className="block text-sm font-medium text-white">Date</label>
        <input
            type="text"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            placeholder="Date"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="vehicleNo" className="block text-sm font-medium text-white">Vehicle No</label>
        <input
            type="text"
            id="vehicleNo"
            name="vehicleNo"
            value={formData.vehicleNo}
            onChange={handleInputChange}
            placeholder="Vehicle No"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="driverName" className="block text-sm font-medium text-white">Driver Name</label>
        <input
            type="text"
            id="driverName"
            name="driverName"
            value={formData.driverName}
            onChange={handleInputChange}
            placeholder="Driver Name"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
</div>

<div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="inandout" className="block text-sm font-medium text-white">In/Out</label>
        <input
            type="text"
            id="inandout"
            name="inandout"
            value={formData.inandout}
            onChange={handleInputChange}
            placeholder="In/Out"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="location" className="block text-sm font-medium text-white">Location</label>
        <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder="Location"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="tankNo" className="block text-sm font-medium text-white">Tank No</label>
        <input
            type="text"
            id="tankNo"
            name="tankNo"
            value={formData.tankNo}
            onChange={handleInputChange}
            placeholder="Tank No"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
</div>


<div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="fuelName" className="block text-sm font-medium text-white">Fuel Name</label>
        <input
            type="text"
            id="fuelName"
            name="fuelName"
            value={formData.fuelName}
            onChange={handleInputChange}
            placeholder="Fuel Name"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="grossWeight" className="block text-sm font-medium text-white">Gross Weight</label>
        <input
            type="number"
            id="grossWeight"
            name="grossWeight"
            value={formData.grossWeight || 0}
            onChange={handleInputChange}
            placeholder="Gross Weight"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="tareWeight" className="block text-sm font-medium text-white">Tare Weight</label>
        <input
            type="number"
            id="tareWeight"
            name="tareWeight"
            value={formData.tareWeight || 0}
            onChange={handleInputChange}
            placeholder="Tare Weight"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
</div>

<div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="netWeight" className="block text-sm font-medium text-white">Net Weight</label>
        <input
            type="number"
            id="netWeight"
            name="netWeight"
            value={formData.netWeight || 0}
            onChange={handleInputChange}
            placeholder="Net Weight"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="uom" className="block text-sm font-medium text-white">UOM</label>
        <input
            type="text"
            id="uom"
            name="uom"
            value={formData.uom}
            onChange={handleInputChange}
            placeholder="UOM"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="color" className="block text-sm font-medium text-white">Color</label>
        <input
            type="text"
            id="color"
            name="color"
            value={formData.color}
            onChange={handleInputChange}
            placeholder="Color"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
</div>
<div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="density" className="block text-sm font-medium text-white">Density</label>
        <input
            type="text"
            id="density"
            name="density"
            value={formData.density}
            onChange={handleInputChange}
            placeholder="Density"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="remarks" className="block text-sm font-medium text-white">Remarks</label>
        <input
            type="text"
            id="remarks"
            name="remarks"
            value={formData.remarks}
            onChange={handleInputChange}
            placeholder="Remarks"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="barrelCount" className="block text-sm font-medium text-white">Barrel Count</label>
        <input
            type="number"
            id="barrelCount"
            name="barrelCount"
            value={formData.barrelCount || 0}
            onChange={handleInputChange}
            placeholder="Barrel Count"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
</div>
<div className="grid grid-cols-1 gap-3 md:grid-cols-3">
    <div>
        <label htmlFor="litresPerBarrel" className="block text-sm font-medium text-white">Litres per Barrel</label>
        <input
            type="number"
            id="litresPerBarrel"
            name="litresPerBarrel"
            value={formData.litresPerBarrel || 0}
            onChange={handleInputChange}
            placeholder="Litres per Barrel"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
            readOnly
        />
    </div>
    <div>
        <label htmlFor="amount" className="block text-sm font-medium text-white">Amount<span className="text-red-500"> *</span></label>
        <input
            type="number"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            placeholder="Amount"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
        />
    </div>
    <div>
        <label htmlFor="gstNo" className="block text-sm font-medium text-white">GST No<span className="text-red-500"> *</span></label>
        <input
            type="text"
            id="gstNo"
            name="gstNo"
            value={formData.gstNo}
            onChange={handleInputChange}
            placeholder="GST No"
            className="text-sm mt-1 block w-full border p-1 text-black border-gray-300 rounded-md shadow-sm"
        />
    </div>
</div>

        </div>
    );
};

export default ExpenseForm;
